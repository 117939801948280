import { BRAND } from '../../constants'
import { TrustedByLogos } from '../UI'

const { media } = BRAND
export const TrustedBy = ({ trustedBy }) => {
  if (!trustedBy) {
    return <></>
  }
  const { section_1, section_2, quote, logos, title } = trustedBy?.banner?.[0]

  return trustedBy.banner_type === 'B2B' ? (
    <TrustedByLogos {...{ title }} />
  ) : (
    <div className='banner'>
      <section className='row'>
        <h5 className='margin'>{`${section_1}`}</h5>
        <div style={{ width: 30 }} className='margin' />
        <div>
          {[1, 2, 3, 4, 5].map((e, i) => (
            <span key={i}>&#9733;</span>
          ))}
        </div>
        <div style={{ width: 10 }} />
        <h5 className='margin'>{section_2}</h5>
      </section>
      <h5 className='quote'>{quote}</h5>
      <style jsx>{`
      .margin {
        margin:8px;
      }
        .banner {
          min-height: 130px;
          width:100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items:center;
          background-color: #f5f5f5;
          padding:16px;
        }
        .row {
            display:flex;
            flex-direction:row;
            align-items: center;
        }
        .quote {
            font-style: italic;
            font-size: 16px;
            margin:8px;
            font-weight:600;
            text-align:center;
        }
        @media screen and (max-width: ${media.medium}px) {
          
        @media screen and (max-width: ${media.small}px) {
          .row {
            flex-direction:column;
          }
        }
      `}</style>
    </div>
  )
}

export default TrustedBy
