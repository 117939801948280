import { Layout } from '../components/Layout'
import { Error } from '../components/Error'

const navType = 'STANDARD'

export const NotFound = ({ errorMessage, megaMenu, footer, locale }) => (
  <Layout {...{ navType, megaMenu, footer }}>
    <Error {...{ errorMessage, locale }} />
  </Layout>
)
