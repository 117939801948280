import { BRAND } from '../../../constants'
import { Carousel } from '../../Carousel/Carousel'
import { ILPCard } from './VenueCard'

export const MoreVenues = ({ venues }) => {
  if (!venues || venues.length === 0) return null

  return (
    <section className='container'>
      <h3 id='more-venues' className='title'>Discover even more venues</h3>
      <Carousel
      headingId='more-venues'
      size="small"
      slides={venues.map((venue, index) => <ILPCard venue={venue} key={`more-venue-${index}`} />)}
      />    
      <style jsx>{`
        .container {
          width: 100%;
          min-height: 314px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          
          gap: 32px;
          background-color: #f4f4f4;
          padding: 72px 16px;
        }

        .title {
          font-weight: 700;
          font-size: 32px;
          line-height: 38px;
          text-align: center;
          letter-spacing: -0.005em;
          color: #1F1F1F;
        }
      `}</style>
    </section>
  )
}
