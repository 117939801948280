import Image from 'next/image'
import { BRAND } from '../../constants'
import { ErrorMessage } from '../UI'
import getCallToActionUrl from '../../utilities/getCallToActionURL'

const { media, primaryColour, card } = BRAND

export const Error = ({ errorMessage, locale }) => {
  if (!errorMessage) {
    return <ErrorMessage />
  }

  const callToActionURL = getCallToActionUrl({ locale })

  const { title, blurb, status, cta } = errorMessage

  return (
    <section className='container' id='#error-ui'>
      <div className='message'>
        <h2 className='title'>{title}</h2>
        <p className='paragraph'>{blurb}</p>
        <p className='paragraph'>{status}</p>

        <a href={callToActionURL} className='cta'>
          {cta}
        </a>
      </div>
      <Image src='/images/404.png' width={800} height={700} alt='Page not found!' />
      <style jsx>
        {`
          .title {
            font-weight: 700;
            font-size: 55px;
          }
          .container {
            display: flex;
            flex-direction: row;
            width: 100%;
            align-items: center;
            justify-content: center;
            align-self: center;
            padding-bottom: 42px;
            gap: 20px;
          }
          .message {
            display: flex;
            flex-direction: column;
            margin: 20px;
            justify-content: space-between;
            align-items: flex-start;
            max-width: 550px;
            gap: 24px;
          }

          .paragraph {
            font-size: 20px;
          }
          .image {
            max-width: 700px;
            margin-top: 40px;
          }
          .cta {
            background-color: ${primaryColour};
            padding: 10px 20px;
            color: white;
            border-radius: 6px;
            font-weight: 600;
            font-size: 14px;
            cursor: pointer;
            text-decoration: none;
          }
          @media screen and (max-width: ${media.medium}px) {
            .container {
              flex-direction: column;
              margin: 20px;
            }
            .image {
              margin: 40px 0px;
            }
            .title {
              font-weight: 700;
              font-size: 42px;
            }
          }
          @media screen and (max-width: ${media.small}px) {
            .title {
              font-weight: 600;
              font-size: 36px;
            }
            .container {
              flex-direction: column;
              margin: 10px;
            }
            .message {
              gap: 16px;
            }
            .image {
              margin: 10px;
            }
          }
        `}
      </style>
    </section>
  )
}
