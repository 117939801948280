import { BRAND, STRAPI_IMGX } from '../../constants'
import { SkyLogo, DentsuLogo, SkyscannerLogo, SnapIncLogo, EdfLogo, LorealLogo } from '../Images'

const LOGOS = [<SkyLogo />, <LorealLogo />, <DentsuLogo />, <SnapIncLogo />, <EdfLogo />, <SkyscannerLogo />]

const TrustedByLogos = ({ title }) => {
  return (
    <div className='logos-bar'>
      <div className='logos'>
        <h5 className='trusted-by'>{title}</h5>
        {LOGOS.map((logo, i) => (
          <section key={`logo-${i}`} className='logo'>
            {logo}
          </section>
        ))}
      </div>
      <style jsx>{`
        .logos-bar {
          width: 100%;
          background-color: ${BRAND.grey};
        }
        .logos {
          align-items: center;
          width: 100%;
          max-width: ${BRAND.layout.content}px;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-around;
          margin: 0 auto;
          padding: 24px;
        }
        .logo {
          padding: 8px 16px;
        }
        @media screen and (max-width: ${BRAND.media.average}px) {
          .trusted-by {
            width: 100%;
            text-align: center;
          }
        }
        @media screen and (max-width: ${BRAND.media.small}px) {
          .logos {
            justify-content: center;
          }
        }
      `}</style>
    </div>
  )
}

export default TrustedByLogos
