import { BRAND } from '../../constants'
const { media } = BRAND

export const CapacityInfo = ({ capacity }) => {
  return (
    <section className='row'>
      <img
        src={'/ilp-public/assets/images/capacity.png'}
        className='icon'
        alt={`venue capacity is  ${capacity}`}
      />
      <p className='subTitle'>{`${capacity}`}</p>
      <style jsx>{`
        .subTitle {
          line-height: 24px;
          font-size: 16px;
          font-weight: 500;
        }
        .icon {
          margin-right: 5px;
          height: 16px;
          width: 16px;
        }
        .row {
          display: flex;
          fexl-direction: row;
          align-items: center;
        }
      `}</style>
    </section>
  )
}
