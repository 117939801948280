import Image from 'next/image'
import { BRAND, STRAPI_IMGX } from '../../constants'
import { externalImageLoader } from '../../utilities/externalImageLoader'

const Title = ({ titleText, displayLocation }) => {
  const [text, end] = titleText.split(/{{location}}/)
  return (
    <h3 className='formatted-title'>
      {text}
      {displayLocation && (
        <>
          <span className='accent'>{displayLocation}</span>
        </>
      )}
      {end && end}
      <style jsx>{`
      .formatted-title, .accent {
        font-weight: 700;
        font-size: 54px;
        line-height: 1.25;
        text-align: center;
        letter-spacing: -0.01em;
        color: #434343;
      }

      .accent {
        color: ${BRAND.primaryColour};
        word-break: break-word;
      }

      @media screen and (max-width: ${BRAND.media.medium}px) {
        .formatted-title, .accent {
          font-size: 48px;
      }
      @media screen and (min-width: ${BRAND.media.small}px) and (max-width: ${BRAND.media.tablet}px) {
        .formatted-title, .accent {
          font-size: 48px;
          // line-height: 48px;
        }
      }
      @media screen and (max-width: ${BRAND.media.small}px) {
        .formatted-title, .accent {
          font-size: 36px;
          // line-height: 44px;
        }
      }
      `}</style>
    </h3>
  )
}

export const BrandHero = ({ brandHero, displayLocation, callToActionURL }) => {
  let { title, sub_title, cta_title, cta_url, images } = brandHero
  const ctaHref =
    cta_url.includes('brief-builder.headbox') || cta_url.includes('plan-my-event') ? callToActionURL : cta_url
  
  const renderImage = (index) => {
    return `${STRAPI_IMGX}/${
      images[index ? index : 0]?.hash + images[index ? index : 0]?.ext
    }?auto=format&sharp=20&con=15&h=260`
  }

  const ColumnImage = ({ imageIndex }) => {
    const source = renderImage(imageIndex)
    return (
      <div className='column-image-wrapper'>
        <Image
          loader={externalImageLoader}
          unoptimized
          objectFit='cover'
          src={source}
          quality={90}
          width={184}
          height={260}
          aria-hidden={true}
          priority
          alt={images[imageIndex]?.alternativeText}
        />
        <style jsx>{`
          .column-image-wrapper {
            width: 184px;
            height: 260px;
            position: relative;
            min-height: 260px;
            border-radius: 4px;
            overflow: hidden;
          }
        `}</style>
      </div>
    )
  }

  return (
    <div className='container'>
      <section className='hero'>
        <div className='column fourth'>
          <ColumnImage imageIndex={0} />
          <ColumnImage imageIndex={1} />
        </div>
        <div className='column third'>
          <ColumnImage imageIndex={2} />
          <ColumnImage imageIndex={3} />
        </div>
        <div className='center'>
          <div className='title'>
            <Title titleText={title} displayLocation={displayLocation} />
          </div>
          <p className='subtitle'>{sub_title}</p>
          <div className='center-row'>
            <div className='column second'>
              <ColumnImage imageIndex={4} />
            </div>
            <div className='column'>
              <a href={ctaHref} className='anchor button-margin'>
                {cta_title}
              </a>
              <div className='first'>
                <ColumnImage imageIndex={5} />
              </div>
            </div>
            <div className='column second'>
              <ColumnImage imageIndex={6} />
            </div>
          </div>
        </div>
        <div className='column third'>
          <ColumnImage imageIndex={7} />
          <ColumnImage imageIndex={8} />
        </div>
        <div className='column fourth'>
          <ColumnImage imageIndex={9} />
          <ColumnImage imageIndex={10} />
        </div>
      </section>
      <style jsx>
        {`
          .container {
            display: flex;
            justify-content: center;
            overflow-x: hidden;
            width: 100%;
            margin-bottom: 16px;
          }

          .title {
            max-width: 100vw;
            width: 864px;
            margin: 48px 0 24px;
          }

          .hero {
            width: 100%;
            min-width: 1386px;
            max-width: 1386px;
            gap: 16px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: flex-end;
            overflow-x: hidden;
            flex: 1 0;
          }

          .center {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            height: 100%;
            max-width: 584px;
            align-items: center;
          }

          .center-row {
            display: flex;
            flex-direction: row;
            gap: 16px;
            align-items: flex-end;
          }

          .column {
            width: 184px;
            display: flex;
            flex-direction: column;
            gap: 16px;
            overflow: hidden;
          }

          .subtitle {
            max-width: 100vw;
            font-size: 18px;
            width: 417px;
            padding: 0 16px 24px;
            text-align: center;
          }

          .anchor {
            text-decoration: none;
            background-color: ${BRAND.primaryColour};
            padding: 8px 12px;
            border-radius: 6px;
            font-size: 16px;
            font-weight: 600;
            color: white;
            text-align: center;
            align-self: center;
          }

          .button-margin {
            margin-bottom: 116px;
          }

          .first {
            height: 100px;
          }

          .second {
            height: 175px;
          }

          .third {
            height: 350px;
          }

          .fourth {
            height: 500px;
          }

          @media screen and (max-width: ${BRAND.media.medium}px) {
            .title {
              width: 676px;
            }
          }

          @media screen and (min-width: ${BRAND.media.small}px) and (max-width: ${BRAND.media.tablet}px) {
            .title {
              padding: 0 24px;
            }
          }

          @media screen and (max-width: ${BRAND.media.small}px) {
            .title {
              padding: 0 16px;
            }

            .subtitle {
              font-size: 16px;
            }

            .button-margin {
              margin-bottom: clamp(8px, 3vw, 24px);
            }
          }
        `}
      </style>
    </div>
  )
}
