import { BRAND } from '../../../constants'
const { media } = BRAND

const SecondaryHowItWorks = ({ callToActionURL, howItWorks }) => {
  const { title, steps, ctaTitle } = howItWorks

  return (
  <div className='main'>
    <div className='module'>
      <div className='title-row'>
      <img src='/ilp-public/assets/images/envelope.png' aria-hidden={true} width={36} height={36} />
        <p className='title'>{title}</p>
      </div>
      <div className='container'>
        <div className='step'>
          <div className='numberCircle'>{1}</div>
          <p className='blurb'>
            {steps[0].blurb}
          </p>
        </div>
        <div className='step text'>
          <div className='numberCircle'>{2}</div>
          <p className='blurb'>
          {steps[1].blurb}
          </p>
        </div>
        <div className='step text'>
          <div className='numberCircle'>{3}</div>
          <p className='blurb'>
          {steps[2].blurb}
          </p>
        </div>
        {ctaTitle && <a href={callToActionURL} className='button listicle short'>
          {ctaTitle}
        </a>}
      </div>
    </div>
    <style jsx>{`
      .main {
        max-width: 1060px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .module {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 16px;
        border: 1px solid #bfbfbf;
        margin: 36px 0;
        padding: 12px 36px 24px 36px;
        box-shadow: 5px 5px 20px #bfbfbf;
      }
      .blurb {
        margin: 5px;
        font-size: 16px;
        line-height: 1.3;
        min-width: 160px;
        max-width: 210px;
        font-size: 15px;
      }
      .button.listicle {
        margin-left: 20px;
      }
      .container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        pading: 12px;
      }
      .numberCircle {
        border-radius: 50%;
        min-width: 24px;
        min-height: 24px;
        padding: 2px 3px 3px 3px;
        background: ${BRAND.primaryColour};
        color: #fff;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        margin: 8px 5px;
      }
      .title-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0px;
        padding: 0px;
      }
      .title {
        font-color: ${BRAND.primaryColour};
        color: ${BRAND.primaryColour};
        text-align: center;
        font-size: 28px;
        font-weight: 700;
        padding: 6px;
        margin: 4px;
      }
      .step {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
      }

      .short {
        min-width: 130px;
      }

      @media screen and (max-width: ${BRAND.media.tablet}px) {
        .container {
          flex-direction: column;
          align-items: center;
        }
        .module {
          width: 100%;
        }
        .title-row {
          max-width: 340px;
          margin: 8px;
        }
        .title {
          max-width: 300px;
          font-size: 21px;
          padding: 12px 3px;
          line-height: 1.1;
          font-weight: 600;
        }
        .blurb {
          max-width: 240px;
        }
        .button.listicle {
          margin: 20px 10px 10px 10px;
        }
        .step {
          margin: 8px 0px;
        }
      }
    `}</style>
  </div>
)
    }

export default SecondaryHowItWorks
