import { Card } from '.'
import { BRAND } from '../../constants'
import { TitleWithHighlight } from '../UI'

export const Gallery = ({
  spaceCard,
  spaces,
  venuesHeading = '',
  displayLocation,
  nicheOrEventName,
  locale
}) => {
  return (
    <div className='container' id='galleryTop' role='grid'>
      {venuesHeading.length > 0 && (
        <section className='gallery-heading'>
          <TitleWithHighlight string={venuesHeading} highlight={{ displayLocation, nicheOrEventName }} />
        </section>
      )}
      <div className='wrap'>
        {spaces.map((space) => (
          <Card key={space.id} {...{ space, button: spaceCard.button, locale }} />
        ))}
      </div>
      <style jsx>{`
        .gallery-heading {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin: 32px 0px;
          width: 100%;
        }
        .container {
          width: 100%;
          max-width: 1350px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          margin: 32px 0px;
        }
        .wrap {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          gap: 16px;
          width: 100%;
          max-width: 1280px;
          padding: 0 32px;
        }

        @media screen and (max-width: ${BRAND.media.small}px) {
          .wrap {
            padding: 0 16px;
          }
        }
      `}</style>
    </div>
  )
}
