export const style = {
  scroll: {
    maxWidth: '90vw',
    maxHeight: '90vh',
    overflow: 'scroll',
    scrollBehavior: 'smooth'
  },
  fixedHeader: {
    position: 'absolute',
  },
  link: {
    marginRight: 15
  },
  button: { margin: 5, padding: 5, backgroundColor: 'lightgrey' },

  logo: {
    height: 35,
    boxShadow: '0px  0px  0px  hsla(0,0%,0%,0)'
  }
}

export const FOOTER_STYLE = {
  footer: {
    minHeight: 400,
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: '64px 24px 32px',
  }
}
