import { BRAND, BRIEF_BUILDER } from '../../constants'
import { TitleWithHighlight } from '../UI'
import { insertDynamicText } from '../../controllers/helpers'
const { media, card } = BRAND

export const ContentTitle = ({
  displayLocation = '',
  nicheOrEventName = '',
  contentTitle,
  standfirst,
  secondaryCta,
  listicle,
  callToActionURL,
}) => {
  standfirst = insertDynamicText(standfirst, displayLocation, nicheOrEventName)

  return (
    <section className='margin'>
      {listicle ? (
        <TitleWithHighlight string={contentTitle} highlight={{ displayLocation, nicheOrEventName }} h2 />
      ) : (
        <TitleWithHighlight string={contentTitle} highlight={{ displayLocation, nicheOrEventName }} h1 />
      )}
      <p className='standfirst'>{standfirst}</p>
      <a
        className='button'
        id='#title-cta'
        href={callToActionURL}>
        {secondaryCta.cta}
      </a>
      <style jsx>{`
        .margin {
          display:flex;
          flex-direction:column;
          align-items:center;
          justify-content:center;
          margin: 36px 0px;
          width:100%;
          padding: 0 16px;
        }
        .standfirst {
          font-weight: 200;
          font-size: 22px;
          line-height: 1.7;
          max-width: 1050px;
          text-align:center;
          margin: 32px 0;
        }
        .content-title {
          margin-top:48px;
          text-align: center;
          max-width:1000px;
          font-size:32px;
          line-height:1.4;
        }
        .button {
          background-color: ${BRAND.primaryColour};
          padding: 10px 20px;
          color: white;
          border-radius: 6px;
          font-weight: 600;
          font-size: 14px;
          cursor: pointer;
          text-decoration: none;
          text-align: center;
        }
       
        @media screen and (max-width: ${media.average}px) {
          .content-title {
            margin: 16px 0px;
            font-size: 32px
            
          }
          .standfirst {
            font-size: 18px;
          
          }
        }
        @media screen and (max-width: ${BRAND.media.tablet}px) {
          .content-title {
            font-size: 28px;
            margin:8px 0px;
          }
          .standfirst {
            font-size: 16px;
            margin:16px 0px;
            font-weight:500;
          }
      `}</style>
    </section>
  )
}
