import { LandingPagesLayout } from '../components/Layout'
import {
  Meta,
  LocationCrumbs,
  Gallery,
  Pagination,
  LongGuide,
  BrandHero,
  ContentTitle,
  Listicle
} from '../components/Landing'
import { LANDING_PAGE_STYLE as s } from '../components/Landing/style'
import { BRAND } from '../constants'
import { TargetContent, HowTo, PrimaryCTA } from '../components/UI'
import { RelatedListicles } from '../components/Landing/Listicle/Related/RelatedListicles'
import getCallToActionUrl from '../utilities/getCallToActionURL'
import { MoreVenues } from '../components/Landing/MoreVenues/MoreVenues'
import { TrustedByBanner } from '../components/Landing/TrustedByBanner'
import { useContentPageTracking } from '../hooks/useContentPageTracking'

const { media } = BRAND

export const LandingPage = ({
  pagination,
  host,
  displayLocation,
  city,
  countryCode,
  isSubLocation,
  nicheOrEventName,
  ilpSlug,
  megaMenu,
  metaTitle,
  metaDescription,
  primaryCta,
  secondaryCta,
  trustedByBanner,
  contentTitle,
  targetEvents,
  targetLocations,
  targetVenues,
  targetBlogs,
  standfirst,
  venuesHeading,
  spaceCard,
  howItWorks,
  eventTypeName,
  collectionId,
  breadcrumbs,
  linkPath,
  schema,
  longGuide,
  footer,
  brandHero,
  spaces,
  listicle,
  pageType,
  locale,
  isNichePage,
  displayName,
  relatedListicles,
  moreVenues,
  baseUrl
}) => {
  useContentPageTracking()
  const callToActionURL = getCallToActionUrl({
    collectionId,
    locale,
    ...(isSubLocation ? { location: displayLocation, city, countryCode } : {})
  })

  const is_blog = listicle && listicle.blog_article
  if (pageType === 'isEventOrNicheTypeOnly') {
    return (
      <LandingPagesLayout {...{ megaMenu, footer, callToActionURL }}>
        <Meta
          {...{ metaTitle, metaDescription, schema, host, ilpSlug, nicheOrEventName, locale, isNichePage }}
        />
        {!is_blog && <BrandHero {...{ brandHero, nicheOrEventName, callToActionURL }} />}
        <div style={{ ...s.container }} className='container'>
          {!is_blog && trustedByBanner && <TrustedByBanner {...{ trustedByBanner }} />}
          {listicle && (
            <>
              <Listicle
                {...{
                  listicle,
                  displayLocation,
                  howItWorks,
                  nicheOrEventName,
                  callToActionURL,
                  locale,
                  baseUrl
                }}
              />
              <RelatedListicles related={relatedListicles} locale={locale} />
            </>
          )}
          {!is_blog && (
            <ContentTitle
              {...{ contentTitle, standfirst, secondaryCta, nicheOrEventName, listicle, callToActionURL }}
            />
          )}
          <TargetContent target={targetEvents} highlight={{ nicheOrEventName }} />
          <TargetContent target={targetVenues} highlight={{ nicheOrEventName }} />
          <TargetContent target={targetLocations} highlight={{ nicheOrEventName }} />
          <TargetContent target={targetBlogs} highlight={{ nicheOrEventName }} />
          {spaces.length > 0 && <Gallery {...{ spaceCard, spaces, venuesHeading, locale }} />}
          {!listicle && <RelatedListicles related={relatedListicles} locale={locale} />}
          <HowTo {...{ howItWorks, callToActionURL }} />
          <LongGuide {...{ longGuide, nicheOrEventName }} />
          {moreVenues && <MoreVenues venues={moreVenues} />}
          {!is_blog && <PrimaryCTA {...{ primaryCta, collectionId, callToActionURL }} />}
          {breadcrumbs && (
            <section className='crumbs'>
              <LocationCrumbs
                {...{
                  eventTypeName: displayName || contentTitle || eventTypeName || 'Venues',
                  breadcrumbs,
                  linkPath,
                  displayLocation,
                  locale
                }}
              />
            </section>
          )}
        </div>
        <style jsx={true}>{`
          .crumbs {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            background-color: #f4f4f4;
            width: 100%;
          }
          @media screen and (max-width: ${media.average}px) {
          }
          @media screen and (max-width: ${BRAND.media.tablet}px) {
          }
        `}</style>
      </LandingPagesLayout>
    )
  }

  return (
    <LandingPagesLayout {...{ megaMenu, footer, callToActionURL }}>
      <Meta
        {...{
          metaTitle,
          metaDescription,
          schema,
          host,
          ilpSlug,
          nicheOrEventName,
          displayLocation,
          locale,
          isNichePage
        }}
      />
      <BrandHero {...{ brandHero, displayLocation, nicheOrEventName, callToActionURL, locale }} />
      <div style={s.container} className='container'>
        {trustedByBanner && <TrustedByBanner {...{ trustedByBanner }} />}
        {listicle && (
          <>
            <Listicle
              {...{
                listicle,
                displayLocation,
                howItWorks,
                nicheOrEventName,
                callToActionURL,
                locale,
                baseUrl
              }}
            />
            <RelatedListicles related={relatedListicles} locale={locale} />
          </>
        )}
        <ContentTitle
          {...{
            contentTitle,
            standfirst,
            displayLocation,
            secondaryCta,
            nicheOrEventName,
            listicle,
            callToActionURL
          }}
        />
        <TargetContent target={targetEvents} highlight={{ displayLocation, nicheOrEventName }} />
        <TargetContent target={targetVenues} highlight={{ displayLocation, nicheOrEventName }} />
        <TargetContent target={targetLocations} highlight={{ displayLocation, nicheOrEventName }} />
        <TargetContent target={targetBlogs} highlight={{ displayLocation, nicheOrEventName }} />
        {spaces.length > 0 && (
          <Pagination {...{ pagination }}>
            <Gallery {...{ spaceCard, spaces, venuesHeading, displayLocation, nicheOrEventName, locale }} />
          </Pagination>
        )}
        {!listicle && <RelatedListicles related={relatedListicles} locale={locale} />}
        <HowTo {...{ howItWorks, callToActionURL }} />
        <LongGuide {...{ longGuide, displayLocation, nicheOrEventName }} />
        {moreVenues && <MoreVenues venues={moreVenues} />}
        <PrimaryCTA {...{ primaryCta, collectionId, callToActionURL }} />
        <section className='crumbs'>
          <LocationCrumbs
            {...{ eventTypeName: eventTypeName || 'Venues', breadcrumbs, linkPath, displayLocation, locale }}
          />
        </section>
      </div>
      <style jsx={true}>{`
        .crumbs {
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          background-color: #f4f4f4;
          width: 100%;
        }
        @media screen and (max-width: ${media.average}px) {
        }
        @media screen and (max-width: ${BRAND.media.tablet}px) {
        }
      `}</style>
    </LandingPagesLayout>
  )
}
