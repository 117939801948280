import { DateTime } from 'luxon'
import { IMGIX, IMGIX_SETTINGS_MINIFIED } from '../../constants'
const { NEXT_PUBLIC_VEE_ENDPOINT, BASE_PATH } = process.env
import * as Sentry from '@sentry/nextjs'
import moment from 'moment'
import { getAuthorUrl } from '../../utilities/getAuthorUrl'

const TOP_LEVEL_NICHE_QUERY = `query TopLevelNicheTypes($locale: String!){
	nicheTypes(where:{
    parent_niche_type_null: true,
    parent_event_type_null: true,
	 	is_single_instance_page: false,
    published: true,
    locale: $locale
  }){
    id
  }
}`

export const insertDynamicText = (content = '', location = '', nicheOrEventName = '') => {
  if (location.length === 0) {
    return content
      ?.replace(/{{niche}}/g, nicheOrEventName)
      .replace(/{{location}}'s/g, '')
      .replace(/in {{location}}/g, '')
      .replace(/{{location}}/g, '')
  }
  const formattedText = content?.replace(/{{location}}/g, location).replace(/{{niche}}/g, nicheOrEventName)

  return formattedText
}

export const createSchema = ({
  spaces,
  breadcrumbs,
  title = '',
  faqs,
  article,
  query,
  location = '',
  nicheOrEventName = '',
  ilpSlug = '',
  locale,
  author
}) => {
  let breadcrumbsSchema = null
  let faqSchema = null
  let venuesSchema = null
  let articleSchema = null
  let authorPageSchema = null

  if (author) {
    authorPageSchema = {
      '@context': 'https://schema.org',
      '@type': 'Person',
      image: author.url,
      jobTitle: author.jobTitle,
      name: author.firstName + ' ' + author.lastName
    }
  }

  if (article) {
    const img = article.venues[0]?.cover[0]?.hash + article.venues[0]?.cover[0]?.ext
    articleSchema = {
      '@context': 'https://schema.org',
      '@type': 'Article',
      author: article.author[0] && {
        '@type': 'Person',
        name: article.author[0].firstName + ' ' + article.author[0].lastName,
        url: getAuthorUrl(
          article.author[0].id,
          article.author[0].firstName,
          article.author[0].lastName,
          article.author[0].locale,
          BASE_PATH
        )
      },
      name: insertDynamicText(article.title, location, nicheOrEventName),
      mainEntityOfPage: `${BASE_PATH}/${query.slug.join('/')}`,
      url: `${BASE_PATH}/${query.slug.join('/')}`,
      headline: insertDynamicText(article.title, location, nicheOrEventName),
      description: article.introduction,
      datePublished: article.created_at,
      publisher: { '@type': 'Organisation', name: 'HeadBox' },
      dateModified: article.updated_at,
      image: IMGIX + img + IMGIX_SETTINGS_MINIFIED,
      articleBody: `${article.venues.map(
        (e, i) => ` ${i + 1}. ${e.name} in ${e.location}. ${e.introduction.replace(/(\r\n|\n|\r)/gm, '')}` // remove markdown
      )}`
    }
  }

  if (faqs && faqs.faqs?.length > 0) {
    const listOfFaqs = faqs.faqs?.map(({ question, answer }, i) => {
      return {
        '@type': 'Question',
        position: i + 1,
        name: question,
        acceptedAnswer: {
          '@type': 'Answer',
          text: answer
        }
      }
    })
    faqSchema = {
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity: listOfFaqs
    }
  }

  if (breadcrumbs && breadcrumbs.length > 0) {
    const listOfBreadcrumbs = [
      ...[...breadcrumbs].reverse().map(({ display, href }, i) => {
        return {
          '@type': 'ListItem',
          position: i + 1,
          item: {
            '@id': `${process.env.BASE_PATH}${locale !== 'GB' ? `/${locale.toLowerCase()}` : ''}${href}`,
            name: display
          }
        }
      }),
      {
        '@type': 'ListItem',
        position: breadcrumbs.length + 1,
        item: {
          name: title,
          '@id': `${process.env.BASE_PATH}/${ilpSlug}`
        }
      }
    ]
    breadcrumbsSchema = {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: listOfBreadcrumbs
    }
  }
  if (spaces) {
    venuesSchema = {
      '@context': 'https://schema.org',
      name: title,
      '@type': 'ItemList',
      itemListElement: spaces.map((a, i) => ({
        '@type': 'ListItem',
        position: `${i + 1}`,
        item: {
          '@id': `${a.venue.name}, ${a.name}`,
          '@type': 'LocalBusiness',
          name: `${a.venue.name}, ${a.name}`,
          description: a.description,
          image: IMGIX + Object.values(a.photos)[0]?.[0]?.[0]?.s3_key + IMGIX_SETTINGS_MINIFIED,
          geo: {
            '@type': 'GeoCoordinates',
            latitude: a.venue.lat,
            longitude: a.venue.lon
          }
        }
      }))
    }
  }

  return {
    venues: venuesSchema,
    faqs: faqSchema,
    breadcrumbs: breadcrumbsSchema,
    article: articleSchema,
    author: authorPageSchema
  }
}

export const createLinkPath = ({ locale, seoSlug, nicheOrEventSlug }) =>
  [
    '',
    ...(locale !== 'GB' ? [locale.toLowerCase()] : []),
    seoSlug,
    '{{location}}',
    ...(!!nicheOrEventSlug ? [nicheOrEventSlug] : [])
  ].join('/')

export const getCuratedVenues = async (spaceIds) => {
  if (spaceIds) {
    const spaceData = await fetch(`${NEXT_PUBLIC_VEE_ENDPOINT}/spaces_by_id?ids=${spaceIds}`)
    const { spaces = [] } = (await spaceData.json()) || {}
    spaces.sort((a, b) => spaceIds.indexOf(a.id) - spaceIds.indexOf(b.id))
    return spaces
  }
  return []
}

export async function getRelatedNicheIds(eventType, nicheType, locale, client) {
  if (eventType) {
    return nicheIdsFromEventType(eventType, locale)
  }
  if (nicheType) {
    const children = nicheType?.niche_type?.child_niche_types ?? []
    const siblings = nicheType?.niche_type?.siblings ?? []

    const filterAndMapIds = (nicheList) => {
      return nicheList
        .filter((niche) => {
          if (niche.locale !== locale) {
            Sentry.captureMessage(`nicheType ${niche.id} does not match parent locale`)
            return false
          }
          return true
        })
        .map(({ id }) => parseInt(id))
    }

    const is_top_level_niche =
      nicheType?.niche_type.parent_event_type === null && nicheType?.niche_type.parent_niche_type === null

    return {
      children: filterAndMapIds(children),
      siblings: filterAndMapIds(siblings),
      include_event_types_as_children: false,
      include_event_types_as_siblings: is_top_level_niche
    }
  }
  if (client) {
    const resp = await client
      .query(TOP_LEVEL_NICHE_QUERY, {
        locale
      })
      .toPromise()
    return {
      siblings: [],
      children: resp.data.nicheTypes.map(({ id }) => id),
      include_event_types_as_children: true,
      include_event_types_as_siblings: false
    }
  }
  return { children: [], siblings: [] }
}

export function nicheIdsFromEventType(eventType, locale) {
  const nicheTypes = eventType.child_niche_types
    .filter((niche) => {
      if (niche.locale !== locale) {
        Sentry.captureMessage(`nicheType ${niche.id} does not match parent locale`)
        return false
      }
      return true
    })
    .map(({ id }) => parseInt(id))

  return {
    children: nicheTypes,
    siblings: [],
    include_event_types_as_siblings: true,
    include_event_types_as_children: false
  }
}

export const getFormattedISODate = (date, locale) => {
  const start = moment(date)
  const dateFormat = locale === 'US' ? 'MMMM Do yyyy' : 'Do MMMM yyyy'
  return start.utc().format(dateFormat)
}
