import { Logo } from '../UI'
import { BRAND } from '../../constants'
import { useRouter } from 'next/router'

const { media } = BRAND

const Header = ({ callToActionURL }) => {
  const { query } = useRouter()
  const link = `${process.env.NEXT_PUBLIC_HOME}${query.slug[0] === 'au' ? '/au' : ''}`

  return (
    <section className='fixedHeader'>
      <div id='page-header' role='banner' className='header'>
        <section className='subHeader'>
          <a className='anchor' href={link} aria-label='Home' title='Home'>
            <Logo />
          </a>
          <a
            className='link'
            href={callToActionURL}
            id='#primary-cta-header'>
            Match with venues
          </a>
        </section>
      </div>
      <style jsx>{`
        .headerLink {
          cursor: pointer;
        }
        .header {
          height: 65px;
          display: flex;
          border: 1px solid #f8f8f8;
          justify-content: center;
          align-items: center;
          top: 0;
          position: fixed;
          width: 100%;
          background-color: white;
          z-index: 9999;
          overflow-x: hidden;
        }
        .subHeader {
          max-width: 1280px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .anchor {
          height: 40px;
        }

        .fixedheader: {
          display: flex;
          height: 65px;
          z-index: 100000000;
          position: absolute;
          top: 0;
        }
        .link {
          background-color: ${BRAND.primaryColour};
          padding: 6px 12px;
          color: white;
          border-radius: 6px;
          font-weight: 600;
          font-size: 14px;
          cursor: pointer;
          text-decoration: none;
          text-align: center;
        }
        @media screen and (max-width: ${media.average}px) {
          .subHeader {
            padding: 0px 20px;
          }
        }
        @media screen and (max-width: ${media.small}px) {
          .link {
            padding: 6px;
            font-size: 12px;
            font-weight: 500;
          }
        }
        @media screen and (max-width: 350px) {
          .link {
            display: none;
            visibility: hidden;
            width: 0px;
          }
        }
      `}</style>
    </section>
  )
}

export default Header
