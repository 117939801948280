import { BRAND } from '../../../../constants'
import { Carousel } from '../../../Carousel/Carousel'
import { ListicleCard } from './ListicleCard'

const localisedTitleText = {
  GB: 'Discover and hire the best venues across HeadBox',
  US: 'Discover and rent top event spaces across the USA',
  AU: 'Related Inspiration',
}

export const RelatedListicles = ({ related, locale }) => {
  if (!related || related.length === 0) return null

  return (
    <section className='container'>
      <h3 id='related-inspiration' className='title'>{localisedTitleText[locale]}</h3>
        <Carousel
          headingId='related-inspiration'
          size="medium"
          slides={related.map((listicle, index) => {
            return <ListicleCard listicle={listicle} key={`listicle-${index}`} />
          })}
          >
          </Carousel>

      <style jsx>{`
        .container {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 72px 16px 64px;
          gap: 32px;
          background: linear-gradient(142.83deg, #434343 0.68%, #141414 100%), #ffffff;
        }

        .title {
          font-weight: 700;
          font-size: 32px;
          line-height: 52px;
          text-align: center;
          letter-spacing: -0.005em;
          color: #ffffff;
        }

        @media screen and (max-width: ${BRAND.media.medium}px) {
       
        }

        @media screen and (max-width: ${BRAND.media.small}px) {
     
        }
      `}</style>
    </section>
  )
}
