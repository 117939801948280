import { useEffect, useRef, useState } from 'react'
import { BRAND } from '../../constants'
import { useCurrentBreakpoint } from '../../hooks/useCurrentBreakPoint'

const SLIDE_SIZES = {
  large: { sm: '100%', md: '80%', lg: '80%' },
  medium: { sm: '100%', md: '50%', lg: '33.3333%' },
  small: { sm: '50%', md: '33.3333%', lg: '16.6667%' }
}

const CAROUSEL_HEIGHTS = {
  large: { height: 370, controlHeight: 169  },
  medium: { height: 500, controlHeight: 259 },
  small: { height: 120, controlHeight: 34 },
}

const getPageSize = (currentBreakpoint, carouselType) => {
  if (['medium', 'average', 'large', 'xl'].includes(currentBreakpoint)) {
    return carouselType === 'small' ? 6 : 3
  }
  if (['tablet'].includes(currentBreakpoint)) {
    return carouselType === 'small' ? 3 : 2
  }
  return carouselType === 'small' ? 2 : 1
}

const CarouselControls = ({ showButtons, handleNavigate, size, isLarge }) => {
  const { showLeft, showRight } = showButtons

  return (
    <div>
      <button
        aria-label='Previous'
        size={size}
        className={`carousel-button left ${showLeft ? '' : 'hidden'}`}
        onClick={(e) => handleNavigate('left')}>
        {'<'}
      </button>
      <button
        aria-label='Next'
        size={size}
        className={`carousel-button right ${showRight ? '' : 'hidden'}`}
        onClick={(e) => handleNavigate('right')}>
        {'>'}
      </button>
      <style jsx>{`
        .carousel-button {
          position: absolute;
          z-index: 500;
          top: ${CAROUSEL_HEIGHTS[size].controlHeight}px;
          width: 32px;
          height: 32px;
          background: #ffffff;
          border: 1px solid #bfbfbf;
          border-radius: 2px;
          font-size: 18px;
          transition: all 0.2s ease-in-out;
          -moz-transition: all 0.2s ease-in-out;
          -webkit-transition: all 0.2s ease-in-out;
          color: #4a4c49;
        }

        .left {
          left: ${isLarge ? 'calc(5% - 18px)' : 0};
        }

        .right {
          right: ${isLarge ? 'calc(5% - 18px)' : 0};
        }

        .hidden {
          visibility: hidden;
          display: none;
        }

        .carousel-button:hover {
          cursor: pointer;
          background-color: ${BRAND.primaryColour};
          border-color: ${BRAND.primaryColour};
          color: white;
          transition: all 0.2s ease-in-out;
          -moz-transition: all 0.2s ease-in-out;
          -webkit-transition: all 0.2s ease-in-out;
        }

        @media screen and (max-width: ${BRAND.media.small}px) {
          .carousel-button {
            top: ${isLarge ? CAROUSEL_HEIGHTS.medium.controlHeight : CAROUSEL_HEIGHTS[size].controlHeight}px;
          }

          .left {
            left: 0;
          }
  
          .right {
            right: 0;
          }
        }
      `}</style>
    </div>
  )
}

const CarouselContainer = ({ children, showButtons, handleNavigate, size, headingId, isLarge }) => {
  return (
    <nav className='outer-carousel' aria-labelledby={headingId}>
      <CarouselControls size={size} isLarge={isLarge} showButtons={showButtons} handleNavigate={handleNavigate} />
      {children}
      <style jsx>{`
        .outer-carousel {
          contain: strict;
          height: ${CAROUSEL_HEIGHTS[size].height}px;
          position: relative;
          width: 100%;
          max-width: ${BRAND.layout.content}px;
        }

        @media screen and (max-width: ${BRAND.media.small}px) {
          .outer-carousel {
            height: ${isLarge ? CAROUSEL_HEIGHTS.medium.height : CAROUSEL_HEIGHTS[size].height}px;
          }
        }
      `}</style>
    </nav>
  )
}

function ScrollArea({ handleScroll, handleNavigate, size, centered, myRef, children, activeSlide, isLarge }) {
  const count = children.length
  return (
    <div className='inner-carousel'>
      <ul className='scroll-container' onScroll={() => handleScroll()} ref={myRef}>
        {children.map((child, index) => (
          <li
            onFocus={() => {
              if (activeSlide !== index && isLarge) {
                activeSlide > index ? handleNavigate('left') : handleNavigate('right')
              }
            }}
            className={`slide ${isLarge ? activeSlide === index ? 'large active' : 'large' : ''}`}
            key={`slide-${index}-key`}
            id={`slide-${index}`}>
            {child}
          </li>
        ))}
      </ul>
      <style jsx>
        {`
          .inner-carousel {
            position: relative;
            width: 100%;
            height: 100%;
            overflow: hidden;
            display: flex;
            direction: ltr;
          }

          .scroll-container {
            scroll-behavior: smooth;
            padding: 0 0 20px;
            overflow-x: auto;
            overflow-y: hidden;
            touch-action: pan-x pan-y pinch-zoom;
            flex-direction: row;
            justify-content: ${centered && !isLarge ? 'center' : 'normal'};
            scroll-snap-type: x mandatory;
            width: 100%;
            height: 100%;
            display: flex;
            outline: none;
            position: relative;
            flex-grow: 1;
            flex-wrap: nowrap;
            box-sizing: content-box;
            list-style: none;
            margin: 0;
          }

          .slide {
            flex: ${`0 0 calc(${SLIDE_SIZES[size].sm} - 16px)`};
            scroll-snap-align: ${ isLarge ? 'center': 'start'};
            scroll-snap-stop: always;
            scroll-margin: 8px;
            display: flex;
            overflow: hidden;
            position: relative;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            margin: 0 8px;
            height: fit-content;
            transition: filter 0.5s;
          }

          .large {
            filter: brightness(50%);
          }

          .active {
            filter: brightness(100%);
          }

          @media screen and (min-width: ${BRAND.media.small}px) {
            .slide {
              flex: ${`0 0 calc(${SLIDE_SIZES[size].md} - ${isLarge ? '32px' : '16px'})`};
              min-width: ${count < 2 ? '410px' : 'initial'};
            }

            .large.slide:first-child {
              margin-left: calc(10% + 16px);
            }

            .large.slide:last-child {
              margin-right: calc(10% + 16px);
            }
          }

          @media screen and (min-width: ${BRAND.media.tablet}px) {
            .slide {
              flex: ${`0 0 calc(${SLIDE_SIZES[size].lg} - ${isLarge ? '32px' : '16px'})`};
              min-width: ${size !== 'small' && count < 3 ? '420px' : 'initial'};
            }
          }
        `}
      </style>
    </div>
  )
}

export const Carousel = ({ slides, size, headingId }) => {
  const currentBreakpoint = useCurrentBreakpoint()
  const isLarge = size === 'large'

  const [showButtons, setShowButtons] = useState({
    showLeft: false,
    showRight: false
  })

  const page = getPageSize(currentBreakpoint, size)
  const [centered, setCentered] = useState(slides.length <= page && !isLarge)
  const [activeSlide, setActiveSlide] = useState(0)
  const largeSlideBreakpoint = page === 3 ? 'lg' : page === 2 ? 'md' : 'sm'
  const slideWidthPercentage = parseFloat(SLIDE_SIZES[size][largeSlideBreakpoint]) / 100

  useEffect(() => {
    const newCenter = slides.length <= page && !isLarge
    setCentered(newCenter)

    setShowButtons({
      showLeft: showButtons.showLeft,
      showRight: !newCenter && slides.length > 1
    })
  }, [page])

  const myRef = useRef(null)

  const handleClick = (dir) => {
    if (!myRef.current) return
    const container = myRef.current
    const bound = container.getBoundingClientRect()
    const displacement = isLarge ? bound.width * slideWidthPercentage : bound.width

    container.scrollLeft += dir === 'left' ? -displacement : displacement
  }
  
  const handleScroll = () => {
    if (!myRef.current) return
    const container = myRef.current
    const isElementFullyScrolledRight =
      Math.abs(container.scrollWidth - container.scrollLeft - container.clientWidth) <= 1

    const isElementFullyScrolledLeft = container.scrollLeft === 0

    if (isLarge) {
      const scrollPoints = container.clientWidth * slideWidthPercentage - 16
      const currentSlide = container.scrollLeft / Math.floor(scrollPoints)

      if (Math.abs(activeSlide - currentSlide) > 0.5) {
        setActiveSlide(currentSlide > activeSlide ? activeSlide + 1 : activeSlide - 1)
      }
    }

    if (
      showButtons.showLeft &&
      showButtons.showRight &&
      !isElementFullyScrolledLeft &&
      !isElementFullyScrolledRight
    )
      return

    setShowButtons({
      showLeft: !isElementFullyScrolledLeft,
      showRight: !isElementFullyScrolledRight
    })
  }

  return (
    <CarouselContainer
      headingId={headingId}
      size={size}
      isLarge={isLarge}
      handleNavigate={handleClick}
      showButtons={showButtons}>
      <ScrollArea
        size={size}
        isLarge={isLarge}
        centered={centered}
        myRef={myRef}
        handleScroll={handleScroll}
        handleNavigate={handleClick}
        activeSlide={activeSlide}>
        {slides}
      </ScrollArea>
    </CarouselContainer>
  )
}
