import { visit } from 'unist-util-visit';
import slugify from 'slugify';

export const addAnchors = () => {
  return (tree) => {
    visit(tree, 'element', (node) => {
      if (node.tagName === 'h1' || node.tagName === 'h2' || node.tagName === 'h3' || node.tagName === 'h4') {
        const text = node.children[0].value;
        const slug = slugify(text, { lower: true });
        node.properties.id = slug;
      }
    });
  };
};
