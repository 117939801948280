import { BRAND } from '../../constants'
import { Error } from '../UI'

const Announcements = ({ announcementBar }) => {
  if (!announcementBar) {
    return <Error />
  }

  const { text = '', linkText = '', link = '' } = announcementBar

  return (
    <div tabIndex='1' id='announcement-bar' className='announce-bar'>
      <p className='text'>
        {text}
        <a className='link' href={link}  id='#cta-announcement'>
          {linkText}
        </a>
      </p>
      <style jsx>{`
      .announce-bar {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        background-color: ${BRAND.primaryColour};
        min-height: 32px;
        z-index: 600;
        padding: 4px;
      }
      .text {
        color: white;
        text-align: center;
      }
      .link {
        text-decoration: underline;
        font-size: 16px;
        color: white;
        cursor: pointer;
        background-color: transparent;
      }
      @media screen and (max-width: ${BRAND.media.small}px) {
        .text {
          font-size: 12px;
        }
        .link {
          display: inline-flex;
          font-size: 12px;
        }
    `}</style>
    </div>
  )
}
export default Announcements
