import Link from 'next/link'
import { useRouter } from 'next/router'
import { BRAND } from '../../constants'
import { useCurrentBreakpoint } from '../../hooks/useCurrentBreakPoint'
const { media, card } = BRAND

export const Pagination = ({ pagination, children }) => {
  const { query } = useRouter()
  const breakpoint = useCurrentBreakpoint()
  
  const SHOW_NUM_OF_PAGES = breakpoint === 'small' ? 4 : breakpoint === 'medium' ? 5 : 6

  const path = (value) => (value ? `/${query.slug.join('/')}?page=${value}` : `/${query.slug.join('/')}`)

  const currentPage = Number(query.page) || 1
  const lastPage = pagination

  const showFewMore = new Array(
    lastPage < SHOW_NUM_OF_PAGES && lastPage >= 2 ? lastPage - 2 : SHOW_NUM_OF_PAGES - 2
  )
    .fill()
    .map((_, i) => {
      return { label: i + 2, value: i + 2 }
    })

  const showInvisible =
    lastPage > SHOW_NUM_OF_PAGES && currentPage + 1 !== lastPage
      ? [{ label: '...', value: currentPage === lastPage ? currentPage - 1 : currentPage + 1 }]
      : []

  const showInvisibleNextToLast =
    lastPage > SHOW_NUM_OF_PAGES && currentPage + 1 === lastPage
      ? [{ label: '...', value: currentPage - 1 }]
      : []

  const prevPageValue = (current) => {
    return current <= 2 ? null : current - 1
  }

  const PAGINATION = [
    { label: '<', value: prevPageValue(currentPage), hidden: currentPage === 1 },
    { label: 1, value: null, hidden: query?.page === '1' },
    ...showFewMore,
    ...showInvisibleNextToLast,
    {
      label: currentPage,
      value: currentPage,
      hidden: currentPage < SHOW_NUM_OF_PAGES || currentPage === lastPage
    },
    ...showInvisible,
    {
      label: lastPage,
      value: lastPage
    },
    { label: '>', value: currentPage + 1, hidden: currentPage === lastPage }
  ]

  const PageController = ({ label, value, hidden, }) => {
    const isSelected =
      (currentPage === value && typeof label === 'number') || (currentPage === 1 && value === null)
    return (
      !hidden && (
        <div className={isSelected ? 'page-selected' : 'page-select'}>
            <a className={isSelected ? 'a-selected' : 'a'} href={path(value)}>
              {label}
            </a>
          <style jsx>{`
            .page-selected {
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: ${BRAND.primaryColour};
              border: solid 1px ${BRAND.primaryColour};
              border-radius: 4px;
              padding: 10px;
              margin: 6px;
              width: 34px;
              height: 34px;
            }
            .page-select {
              display: flex;
              justify-content: center;
              align-items: center;
              border: solid 1px #bfbfbf;
              border-radius: 4px;
              background-color: white;
              padding: 10px;
              margin: 6px;
              width: 34px;
              height: 34px;
            }

            .page-select:hover {
              background-color: ${BRAND.primaryColour};
              border: solid 1px ${BRAND.primaryColour};
            }
            .page-select:active {
              background-color: ${BRAND.primaryColour};
              border: solid 1px ${BRAND.primaryColour};
            }
            .a-selected {
              text-decoration: none;
              text-align: center;
              font-size: 16px;
              font-weight: 600;
              color: white;
              padding: 12px;
              cursor: pointer;
            }
            .a {
              text-decoration: none;
              text-align: center;
              font-size: 16px;
              font-weight: 500;
              padding: 12px;
              cursor: pointer;
            }
            .a:hover {
              color: white;
              font-weight: 600;
            }
            .a:active {
              color: white;
              font-weight: 600;
            }
            @media screen and (max-width: ${media.small}px) {
              .page-select {
                margin: 4px;
              }
              .page-selected {
                margin: 4px;
              }
              a. {
                font-size: 12px;
              }
              .a-selected {
                font-size: 12px;
              }
            }
          `}</style>
        </div>
      )
    )
  }

  return (
    <div className='container'>
      {children}
      <section className='pagination'>
        {lastPage > 1 &&
          PAGINATION.map(({ label, value, hidden }, index) => (
            <PageController key={index} {...{ label, value, hidden }} />
          ))}
      </section>
      <style jsx>{`
        .pagination {
          display: flex;
          flex-direction: row;
          width: 100%;
          justify-content: center;
        }
        .container {
          width: 100%;
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          margin-bottom: 64px;
        }
      `}</style>
    </div>
  )
}
