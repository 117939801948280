import ReactMarkdown from 'react-markdown/react-markdown.min'
import { Layout } from '../components/Layout'
import { Meta } from '../components/Landing'

export const SimpleContentPage = ({
  page,
  megaMenu,
  footer
}) => (
  <Layout {...{ navType: 'STANDARD', megaMenu, footer }}>
    <Meta metaTitle={page.title} />
    <article className='textBlock'>
      <h2>{page.title}</h2>
      <ReactMarkdown>{page.body}</ReactMarkdown>
    </article>
    <style jsx>{`
      .textBlock {
        max-width: 800px;
        margin: 0 auto;
        padding: 48px 24px 0;
      }
      .textBlock :global(h4),
      .textBlock :global(p),
      .textBlock :global(li) {
        font-size: 16px;
        line-height: 1.4;
        font-weight: 300;
      }
      .textBlock :global(h4) {
        font-weight: 400;
      }
      .textBlock :global(h4),
      .textBlock :global(p),
      .textBlock :global(ul) {
        margin: 16px auto;
      }
      .textBlock :global(ul) {
        list-style: unset;
        padding-left: 24px;
      }
    `}</style>
  </Layout>
)