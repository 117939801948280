import { BRAND } from '../../constants'
import _ from 'lodash'

const { media, layout } = BRAND
export const LocationCrumbs = ({ eventTypeName, breadcrumbs: _breadcrumbs, displayLocation, locale }) => {
  const breadcrumbs = [..._breadcrumbs]
  breadcrumbs.reverse()
  if (breadcrumbs.length === 0) return null

  const getLocalisedPath = (_href) => {
    return locale === 'GB' ? _href : `/${locale.toLowerCase()}${_href}`
  }

  return (
    <>
      <nav aria-label='Breadcrumb' className='breadcrumb'>
        <ol className='row'>
          {breadcrumbs.map((crumb, index) => {
            return (
              <li key={index} className='link'>
                {crumb.level === 'state' ? (
                  <div className='breadcrumb-element'>{crumb.display}</div>
                ) : (
                    <a href={getLocalisedPath(crumb.href)} className='anchor'>
                      {crumb.display}
                    </a>
                )}
                {(eventTypeName || index < breadcrumbs.length - 1) && <div className='icon'>&#8250;</div>}
              </li>
            )
          })}
          {eventTypeName && (
            <h5 className='breadcrumb-element'>
              {displayLocation} {eventTypeName}
            </h5>
          )}
        </ol>
      </nav>

      <style jsx>{`
        nav {
          width: 100%;
          max-width: ${layout.content}px;
          flex-wrap: wrap;
          margin: 0px 40px;
        }
        .breadcrumb-element {
          text-transform: capitalize;
          font-size: 18px;
          line-height: 1.5;
          font-weight: 500;
          padding: 0px 0px 0px 8px;
        }
        .icon {
          margin: 2px 3px 2px 7px;
        }
        .row {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          max-width: ${layout.content}px;
          margin: 30px 0px;
          list-style: none;
          padding: 0;
        }
        .anchor {
          font-size: 18px;
          cursor: pointer;
          font-weight: 500;
        }
        .anchor:hover {
          color: ${BRAND.primaryColour};
        }
        .link {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0px 5px;
        }
        @media screen and (max-width: ${media.average}px) {
        }
        @media screen and (max-width: ${media.medium}px) {
        
        }
        @media screen and (max-width: ${media.small}px) {
          nav {
          }
        }
      `}</style>
    </>
  )
}

export default LocationCrumbs
