import { BRAND } from '../../constants'
import { TrustedByLogos } from '../UI'

const TrustSection = ({ copy, icon }) => {
  if (!icon && !copy) return null
  return (
    <div className='trust-section'>
      { icon && <img aria-hidden="true" className='trust-section-icon' src={icon.url} /> }
      { copy && <p className='trust-text'>{copy}</p> }
      <style jsx>{`
        .trust-section {
          display: flex;
          align-items: center;
          text-align: center;
        }

        .trust-section-icon {
          margin-right: 8px;
        }

        .trust-text {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
        }

        @media screen and (max-width: ${BRAND.media.tablet}px) {
          .trust-section {
            display: inline;
          }
        }

        @media screen and (max-width: ${BRAND.media.small}px) {
          .trust-section {
            display: block;
          }
        }
      `}</style>
    </div>
  )
}

export const TrustedByBanner = ({ trustedByBanner }) => {
    const { banner, banner_type } = trustedByBanner
    const { section_1, section_1_icon, section_2, section_2_icon, section_3, section_3_icon, quote, title } = banner[0]

    if (banner_type === 'B2B') return (<TrustedByLogos {...{ title }} />)

    return (
      <div className='container'>
        <div className='banner'>
          <div className='trust-sections'>
            <TrustSection copy={section_1} icon={section_1_icon} />
            <TrustSection copy={section_2} icon={section_2_icon} />
            <TrustSection copy={section_3} icon={section_3_icon} />
          </div>
          <p className='trust-quote'>{quote}</p>
        </div>
        <style jsx>{`
          .container {
            width: 100%;
            background-color: ${BRAND.grey};
          }
          .banner {
            width: 100%;
            max-width: ${BRAND.layout.narrowContent}px;
            padding: 32px;
            gap: 16px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0 auto;
          }

          .trust-sections {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
            width: 100%;
            gap: 32px;
          }

          .trust-quote {
            font-style: italic;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
          }

          @media screen and (max-width: ${BRAND.media.tablet}px) {
          }

          @media screen and (max-width: ${BRAND.media.small}px) {
            .banner {
              padding: 24px 16px;
            }

            .trust-sections {
              gap: 8px;
            }
          }
        `}</style>
      </div>
    )
  }
