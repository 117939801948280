import { ListicleAuthors } from './ListicleAuthors'

const WrittenBy = ({ author, locale, listicleDate, baseUrl }) => {
  return (
    <div className='written-by'>
      <p>
        <ListicleAuthors baseUrl={baseUrl} authors={author} listicleDate={listicleDate} locale={locale} />
      </p>
      <style jsx>{`
        .written-by {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          text-align: center;
          max-width: 800px;
          width: 100%;
          margin: 0px 6px;
        }
      `}</style>
    </div>
  )
}

export default WrittenBy
