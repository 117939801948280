import Image from 'next/image'
import { useState } from 'react'
import { getFormattedISODate } from '../../../controllers/helpers'
import { getAuthorUrl } from '../../../utilities/getAuthorUrl'
import { STRAPI_IMGX } from '../../../constants'
import { externalImageLoader } from '../../../utilities/externalImageLoader'

const renderImage = (author) => {
  return `${STRAPI_IMGX}/${author.hash + author.ext}?fit=facearea&facepad=4&w=400&h=400`
}

export const ListicleAuthors = ({ authors, baseUrl, listicleDate, locale }) => {
  const [hoveredAuthorId, setHoveredAuthorId] = useState(null)

  const handleMouseEnter = (authorId) => {
    setHoveredAuthorId(authorId)
  }

  const handleMouseLeave = () => {
    setHoveredAuthorId(null)
  }

  return (
    <>
      <div style={{ padding: '0', margin: '0' }}>
        <ul className='grid-break' style={{ padding: '0', margin: '0' }}>
          <span style={{ gridArea: 'text' }}>
            <span className='authorName'>Written by </span>
            {authors.map((author, i) => (
              <li style={{ display: 'inline' }} key={author.id}>
                <span className='authorName'>
                  {i === authors.length - 1 && authors.length !== 1 && ' and '}
                </span>
                <a
                  style={{ textDecoration: 'none', marginLeft: '0px' }}
                  href={getAuthorUrl(author.id, author.firstName, author.lastName, author.locale, baseUrl)}>
                  <span
                    key={author.id}
                    className={`authorName ${hoveredAuthorId === author.id ? 'hovered' : ''}`}
                    onMouseEnter={() => handleMouseEnter(author.id)}
                    onMouseLeave={handleMouseLeave}>
                    {author.firstName} {author.lastName}
                  </span>
                </a>
                <span className={'authorName'}>
                  {i !== authors.length - 1 && authors.length !== 2 && ', '}
                </span>
              </li>
            ))}
          </span>
          <span className='mobile-break' style={{ gridArea: 'icon', marginLeft: '8px' }}>
            {authors.map((author) => (
              <a
                key={author.id}
                href={getAuthorUrl(author.id, author.firstName, author.lastName, author.locale, baseUrl)}>
                <div
                  className={`authorImageContainer ${hoveredAuthorId === author.id ? 'hovered' : ''}`}
                  onMouseEnter={() => handleMouseEnter(author.id)}
                  onMouseLeave={handleMouseLeave}
                  style={{
                    borderRadius: '50%',
                    width: '40px',
                    height: '40px',
                    overflow: 'hidden',
                    display: 'inline-block'
                  }}>
                  <Image
                    src={renderImage(author)}
                    loader={externalImageLoader}
                    alt={`${author.firstName} ${author.lastName}`}
                    width='40px'
                    height='40px'
                    objectFit='cover'
                  />
                </div>
              </a>
            ))}
          </span>
          <span className='mobile-break' style={{ gridArea: 'date', color: '#595959' }}>
            {'   '}
            {getFormattedISODate(listicleDate, locale)}
          </span>
        </ul>
      </div>

      <style jsx>{`
        .authorImageContainer {
          border: 2px solid transparent;
          transition: border-color 0.3s ease;
          position: relative;
          top: 10px;
        }

        .authorImageContainer.hovered {
          border-color: #af231c;
        }

        .authorName {
          color: #595959;
          text-align: center;
          font-family: Montserrat;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
                  }

        .authorName.hovered {
          color: #af231c;
          transition: color 0.3s ease;
        }
  
        @media (max-width: 768px) {
          .authorImageContainer{
            margin-bottom: 8px;
          }
          .grid-break {
            display: grid;
              grid-template-columns: 1fr;
              grid-template-areas:
                'icon'
                'text'
                'date';
      `}</style>
    </>
  )
}
