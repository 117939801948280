import React from 'react'
import Footer from './Footer'
import MegaMenu from './MegaMenu'
import { BRAND } from '../../constants'

const { media } = BRAND

const LandingPagesLayout = ({ children, megaMenu, footer }) => {
  return (
    <div>
      <MegaMenu {...{ megaMenu }} />
      <main id='main-page' className='content'>
        {children}
      </main>
      <Footer {...{ footer }} />
    </div>
  )
}

export default LandingPagesLayout
