import { BRAND } from '../../constants'
import Highlighter from 'react-highlight-words'
import { insertDynamicText } from '../../controllers/helpers'

const { media } = BRAND

export const TitleWithHighlight = ({
  string,
  highlight = { nicheOrEventName: '', displayLocation: '' },
  h2,
  h1
}) => {
  const style = {
    unHighlighted: {
      textAlign: 'center',
      fontWeight: 700,
    },
    highlighted: {
      textAlign: 'center',
      fontWeight: 700,
      color: BRAND.primaryColour,
      backgroundColor: BRAND.white,
      paddingLeft: 0,
      paddingRight: 0,
      margin: 0
    }
  }
  string = insertDynamicText(string, highlight.displayLocation, highlight.nicheOrEventName)
  if (h1) {
  }
  return (
    <div>
      {h1 && (
        <h1 className='title'>
          <Highlighter
            highlightClassName='title'
            highlightStyle={style.highlighted}
            unhighlightStyle={style.unHighlighted}
            searchWords={[highlight.displayLocation, highlight.nicheOrEventName]}
            autoEscape={true}
            textToHighlight={string}
          />
        </h1>
      )}
      {h2 && (
        <h2 className='title'>
          <Highlighter
            highlightClassName='title'
            highlightStyle={style.highlighted}
            unhighlightStyle={style.unHighlighted}
            searchWords={[highlight.displayLocation, highlight.nicheOrEventName]}
            autoEscape={true}
            textToHighlight={string}
          />
        </h2>
      )}
      {!h2 && !h1 && (
        <h3 className='title'>
          <Highlighter
            highlightClassName='title'
            highlightStyle={style.highlighted}
            unhighlightStyle={style.unHighlighted}
            searchWords={[highlight.displayLocation, highlight.nicheOrEventName]}
            autoEscape={true}
            textToHighlight={string}
          />
        </h3>
      )}
      <style jsx>{`
        .title {
          font-size:32px;
          text-align:center;
          text-wrap: balance;
          font-weight:700;
          font-color:#1f1f1f;
        }
        @media screen and (max-width: ${media.average}px) {
         
        }
        @media screen and (max-width: ${BRAND.media.tablet}px) {
          .title {
            font-size: 28px;
            line-height:1.3;
          }
        }
        @media screen and (max-width: ${BRAND.media.small}px) {
          .title {
            font-size:32px;
            font-weight:700;
            font-size: 24px;
          }
        }
      `}</style>
    </div>
  )
}
