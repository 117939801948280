import { BRAND } from "../../../constants"

export const ILPCard = ({ venue }) => {
  const { title, url } = venue

  return (
      <a href={url} className='item-link'>
        <div className='card'>
            <h4 className='title'>{title}</h4>
        </div>
      
      <style jsx>{`

        .item-link:hover .card,
        .item-link:focus-visible .card {
          background: ${BRAND.primaryColour};
          border: 1px solid #BFBFBF;
          transition: all 0.2s ease-in-out;
          -moz-transition: all 0.2s ease-in-out;
          -webkit-transition: all 0.2s ease-in-out;
        }

        .item-link:hover .title,
        .item-link:focus-visible .title {
          color: ${BRAND.white};
          transition: all 0.2s ease-in-out;
          -moz-transition: all 0.2s ease-in-out;
          -webkit-transition: all 0.2s ease-in-out;
        }

        .item-link {
          text-decoration: none;
        }

        .item-link:focus-visible {
          outline: none;
        }

        .card {
          display: flex;
          scroll-snap-align: start;
          text-align: center;
          width: 100%;
          min-height: 100px;
          background-color: #FFFFFF;
          border: 1px solid #BFBFBF;
          border-radius: 8px;
          padding: 12px 16px;
          transition: all 0.3s ease-in-out;
          -moz-transition: all 0.3s ease-in-out;
          -webkit-transition: all 0.3s ease-in-out;
        }

        .title {
          transition: all 0.3s ease-in-out;
          -moz-transition: all 0.3s ease-in-out;
          -webkit-transition: all 0.3s ease-in-out;
          color: #1f1f1f;
          font-weight: 600;
          font-size: 18px;
          line-height: 24px;
          margin: auto;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

      `}</style>
    </a>
  )
}
