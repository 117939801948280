import { BRIEF_BUILDER as BRIEF_BUILDER_URL, SOFT_LAUNCH_URLS, isActiveLocale } from '../constants'
import titleCase from './textTransformation/titleCase'

const buildLocationString = ({ location, countryCode, city }) => {
  if (location && countryCode && city) {
    // casing not guaranteed to survive HTTP but worth a shot
    return `${titleCase(location)}, ${titleCase(city)}, ${countryCode.toUpperCase()}`
  }

  return ''
}

const getCallToActionUrl = ({ collectionId, locale, ...locationParams }) => {
  if (!isActiveLocale(locale)) {
    return SOFT_LAUNCH_URLS[locale]
  }
  const eventTypeQuery = collectionId ? `collection-id=${encodeURIComponent(collectionId)}` : ''
  const locationStr = buildLocationString(locationParams)
  const locationQuery = locationStr && `location=${encodeURIComponent(locationStr)}`
  const query = [eventTypeQuery, locationQuery].filter((q) => q !== '').join('&')

  return `${BRIEF_BUILDER_URL}${query === '' ? '' : `?${query}`}`
}

export default getCallToActionUrl
