import { BRAND } from '../../constants'

const { media, black, white } = BRAND
const MAX_CHARS = 110

export const PrimaryCTA = ({ primaryCta, callToActionURL }) => {
  const { cta, title, blurb } = primaryCta

  return (
    <div className='card'>
      <h3 className='heading'>{title}</h3>
      <h3 className='paragraph'>{blurb.length > MAX_CHARS ? `${blurb.substr(0, MAX_CHARS)}...` : blurb}</h3>
      <div 
      >
        <a
          className='button'
          id='#primary-cta'
          href={callToActionURL}
          >
          {cta}
        </a>
      </div>
      <style jsx>{`
        .heading {
          font-weight: bold;
          font-size: 40px;
          line-height: 52px;
          text-align: center;
          font-color: white;
          color: white;
          margin: 16px;
          max-width: 650px;
        }
        .paragraph {
          font-size: 18px;
          max-width: 550px;
          color: white;
          line-height: 24px;
          margin: 16px 0 32px;
          text-align: center;
          font-weight: 600;
        }
        .button {
          background-color: ${white};
          padding: 10px 20px;
          margin: 20px;
          color: ${black};
          border-radius: 4px;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          cursor: pointer;
          text-decoration: none;
          text-align: center;
        }
        .card {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 500px;
          color: white;
          background: linear-gradient(142.83deg, #AF231C 15.51%, #5C110E 100%), #FFFFFF;
        }
        @media screen and (max-width: ${media.average}px) {
        }
        @media screen and (max-width: ${media.small}px) {
          .heading {
            font-size: 38px;
            max-width: 400px;
          }
        }
      `}</style>
    </div>
  )
}
