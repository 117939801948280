import { BRAND } from '../../constants'
import { useCurrentBreakpoint } from '../../hooks/useCurrentBreakPoint'

export const HowTo = ({ howItWorks, callToActionURL }) => {
  const { title, steps, ctaTitle, icon } = howItWorks

  const breakpoint = useCurrentBreakpoint()
  const isMobile = breakpoint === 'small'

  const Step = ({ index }) => {
    return (
      <li className='step'>
        <div className='step-count'>{index + 1}</div>
        <p className='blurb'>{steps[index].blurb}</p>
        <style jsx>{`
          .step {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 18px;
          }
          .step-count {
            width: 32px;
            min-width: 32px;
            height: 32px;
            min-height: 32px;
            background: #ffffff;
            font-weight: 600;
            border-radius: 50%;
            color: #af231c;
            font-size: 18px;
            line-height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .blurb {
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            color: #ffffff;
          }

          @media screen and (max-width: 1000px) {
            .blurb {
              max-width: 275px;
            }
          }

          @media screen and (max-width: ${BRAND.media.small}px) {
            .blurb {
              max-width: 400px;
              font-size: 16px;
              line-height: 24px;
            }
          }
        `}</style>
      </li>
    )
  }

  return (
    <section className='banner-container'>
      <div className='banner'>
        <span className='title-container'>
        {isMobile && icon && <img className='icon' src={icon?.url} aria-hidden="true" />}
        <h3 className='title'>
          {!isMobile && icon && <img className='icon' src={icon?.url} aria-hidden="true" />}
          {title}
          </h3>
        </span>
        <ul className='step-row'>
          <ol className='two-step'>
            <Step index={0} />
            <Step index={1} />
          </ol>
          <ol className='two-step'>
            <Step index={2} />
            {ctaTitle && <li className='anchor-container'>
              <a className='anchor' href={callToActionURL}>
              {ctaTitle}
              </a>
            </li> }
          </ol>
        </ul>
      </div>
      <style jsx>{`
        .banner-container {
          display: flex;
          justify-content: center;
          padding: 48px 32px;
          width: 100%;
        }

        .banner {
          display: flex;
          flex-direction: column;
          align-items: center;
          background: linear-gradient(142.83deg, #af231c 15.51%, #5c110e 100%);
          border-radius: 12px;
          width: 100%;
          max-width: 1162px;
          padding: 48px 32px;
          margin: 0 auto;
        }

        .step-row {
          display: flex;
          gap: 32px;
          padding: 24px 10px 7px;
          list-style: none;
        }

        .two-step {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          gap: 32px;
          list-style: none;
          padding: 0;
        }

        .title {
          font-weight: 700;
          font-size: 32px;
          line-height: 52px;
          color: #ffffff;
          text-align: center;
        }

        .anchor {
          background: #ffffff;
          border-radius: 4px;
          font-weight: 600;
          color: #1f1f1f;
          font-size: 16px;
          line-height: 24px;
          padding: 8px 16px;
          white-space: nowrap;
          text-decoration: none;
        }

        .icon { 
          height: 36px;
          margin-right: 8px;
        }

        @media screen and (max-width: 1000px) {
          .step-row {
            flex-wrap: wrap;
            justify-content: space-between;
          }

          .two-step {
            flex-basis: 100%;
            justify-content: space-between;
          }

          .anchor-container {
            flex-grow: 1;
            display: flex;
            justify-content: center;
          }
        }

        @media screen and (max-width: ${BRAND.media.small}px) {
          .banner-container {
            padding: 36px 16px;
          }

          .banner {
            padding: 48px 26px;
          }

          .two-step {
            flex-direction: column;
            align-items: flex-start;
          }

          .title-container {
            margin-left: 7px;
            display: flex;
            gap: 15px;
            align-items: center;
            align-self: flex-start;
          }

          .title {
            font-size: 24px;
            line-height: 32px;
            text-align: left;
          }

          .icon {
            margin-right: 0px;
          }

          .anchor {
            padding: 5px 10px;
          }

          .anchor-container {
            width: 100%;
            flex-grow: 1;
            display: flex;
            justify-content: center;
          }
        }
      `}</style>
    </section>
  )
}
