import { BRAND } from '../../constants'
import { insertDynamicText } from '../../controllers/helpers'
import { useEffect, useRef, useState } from 'react'
import { useDebouncedWindowSize } from '../../hooks/useDebouncedWindowSize'
import ReactMarkdown from 'react-markdown'
import { overflowComponents } from '../../utilities/markdownOperations'
const { media } = BRAND

const Faqs = ({ faq_title, faqs }) => {

  const [openFAQ, setOpenFAQ] = useState(null)

  const handleExpand = (rowIndex) => {
    if (openFAQ === rowIndex) {
      setOpenFAQ(null)
    } else {
      setOpenFAQ(rowIndex)
    }
  }
  
  return (
  <section className='faqs'>
    <h5 className='FAQ-title'>{faq_title}</h5>
    <div id='accordion' className='accordion'>
      {faqs.map((faq, i) => (
        <section className='accordion-tab' key={i} id={`section${i}`}>
          <h5 className='title'>
            <button
              className='row'
              onClick={() => handleExpand(i)}
              >
              <span id={`plus${i}`} className='row'>
                <span>{faq.question}</span> { openFAQ === i ? <span>&#8722;</span> : <span>&#43;</span> }
              </span>
            </button>
          </h5>
          <section className={`answer ${ openFAQ === i && 'show'}`}>
            <ReactMarkdown>{faq.answer}</ReactMarkdown>
          </section>
        </section>
      ))}
    </div>
    <style jsx>{`
      .faqs {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 0 32px;
      }
      .FAQ-title {
        font-weight: 600;
        font-size: 24px;
        margin: 24px 0px;
        line-height: 1.5;
      }
      .accordion {
        width: 100%;
        max-width: 750px;
      }
      .accordion-tab {
        border-top: 1px solid lightgrey;
      }
      .FAQ-section {
        display: flex;
        flex-direction: row;
        background-color: transparent;
        justify-content: space-between;
        align-items: center;
        border: 0px;
        width: 100%;
      }
      .title {
        font-weight: 600;
        font-size: 18px;
        background-color: transparent;
        border: 0px;
        padding: 16px 0px;
      }
      .row {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        background: transparent;
        font-size: 18px;
        font-weight: 500;
        text-align: left;
      }
      .answer {
        display: none;
        visibility: hidden;
        padding-bottom: 12px;
        cursor: intial;
      }
      .show {
        display: block;
        visibility: visible;
      }

      @media screen and (max-width: ${media.small}px) {
        .FAQ-title {
          font-size: 18px;
        }
        .title {
          font-size: 16px;
          padding: 12px 0px;
        }
        .faqs {
          padding: 0;
        }
      }
    `}</style>
  </section>
)
}

export const LongGuide = ({ longGuide, displayLocation = '', nicheOrEventName = '' }) => {
  let { title, guide, expand_text, close_text, faq_title, faqs } = longGuide || {}
  title = insertDynamicText(title, displayLocation, nicheOrEventName)
  const guideRef = useRef(null)
  const [truncated, setTruncated] = useState(null)
  const { width: screenWidth } = useDebouncedWindowSize()


  useEffect(() => {
    if (!guideRef.current ) return
    if (guideRef.current.clientHeight >= guideRef.current.scrollHeight && truncated === null) return

    if (guideRef.current.clientHeight >= guideRef.current.scrollHeight && truncated !== 'expanded') {
      setTruncated(null)
      return
    }

    if (typeof truncated !== 'string') {
      setTruncated('truncated')
    } 
  },[guideRef, screenWidth])

  const handleExpand = () => {
    if (truncated === 'truncated') {
      setTruncated('expanded')
    } else {
      setTruncated('truncated')
      const heading = document.getElementById('guide-heading')
      heading.scrollIntoView({ block: 'center'})
    }
  }

  return (
    longGuide && (
      <section className='container'>
        <h3 aria-level='1' id="guide-heading" className='heading'>
          {title}
        </h3>

          <div>
            <div 
            className={truncated === 'expanded' ? 'full' : 'truncated'}
            ref={guideRef}>
            <ReactMarkdown components={overflowComponents}>{guide}</ReactMarkdown>
            </div>
          </div>
        <div className={typeof truncated !== 'string' ? 'hide' : ''}>
          <button onClick={() => handleExpand()} className='read-more'>
            <h5 className='read-more-font'>{truncated === 'truncated' ? expand_text : close_text}</h5>
          </button>

        </div>
        

        {faqs.length > 0 && <Faqs {...{ faq_title, faqs }} />}
        <style jsx>{`
          .container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            padding: 0 32px 64px;
            max-width: 800px;
          }
          .hide {
            display: none;
            visibility: hidden;
          }


          .truncated {
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
          }

          .full {
            display: block;
          }

          .heading {
            font-size: 40px;
            weight: 700px;
            line-height: 52px;
            text-align: center;
            margin: 28px 0px;
          }
          .read-more {
            background: transparent;
            cursor: pointer;
            width: 100%;
            text-align: center;
            margin: 12px;
          }
          .read-more-font {
            font-weight: 600;
          }
          .read-less-font {
            font-weight: 600;
            background: transparent;
            cursor: pointer;
            width: 100%;
            text-align: center;
            margin: 12px;
            font-size: 16px;
          }
          @media screen and (max-width: ${media.small}px) {
            .heading {
              font-size: 28px;
              line-height: 40px;
            }
          }
        `}</style>
      </section>
    )
  )
}

export default LongGuide
