import { BRAND } from '../../constants'

const st = {
  loader: {
    border: `1px solid ${BRAND.primaryColour}`,
    borderRadius: 4,
    backgroundColor: 'transparent',
    padding: '7px 14px',
    color: BRAND.primaryColour,
    cursor: 'pointer',
    margin: 15
  },
  primary: {
    height: 28,
    backgroundColor: BRAND.primaryColour,
    color: 'white',
    fontSize: 12,
    fontWeight: 600,
    padding: '4px 15px',
    cursor: 'pointer',
    borderRadius: 4,
    margin: '0px 15px',
    minWidth: 150
  },

  secondary: {
    height: 25,
    backgroundColor: 'white',
    color: BRAND.primaryColour,
    fontsSize: 12,
    padding: '3px 6px',
    cursor: 'pointer',
    borderRadius: 4,
    maxWidth: '100%'
  },
  link: {
    height: 25,
    fontSize: 16,
    padding: '6px 12px',
    cursor: 'pointer',
    backgroundColor: 'transparent'
  },
  home: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    backgroundColor: 'white',
    color: BRAND.darkGrey,
    padding: '3px 6px',
    cursor: 'pointer',
    borderRadius: 4,
    padding: '6px 18px',
    fontSize: 18,
    fontWeight: 500,
    maxWidth: '100%',
    textDecoration: 'none'
  }
}
export const ButtonLink = ({
  primary,
  secondary,
  strong,
  link,
  children,
  id,
  home,
  width,
  href
}) => {
  if (primary)
    return (
      <a id={id} style={{ ...st.primary, width }} href={href}>
        {children}
      </a>
    )
  if (link) {
    return (
      <a id={id} style={{ ...st.link, fontWeight: strong ? 'bold' : 400 }} href={href}>
        {children}
      </a>
    )
  }
  if (secondary)
    return (
      <a id={id} style={{ ...st.secondary, width }} href={href}>
        {children}
      </a>
    )
  if (home)
    return (
      <a tabIndex={0} id={id} href={href} style={{ ...st.home }}>
        {children}
      </a>
    )
  return (
    <a id={id} style={st.loader}>
      {children}
    </a>
  )
}
