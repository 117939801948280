import { useEffect, useState } from 'react'
import { singletonHook } from 'react-singleton-hook'
import { BRAND } from '../constants'

export function _useCurrentBreakpoints() {

  const breakpoints = Object.keys(BRAND.media)

  const mqs = breakpoints.map(
    (key, index, breakpoints) => {
      const correctedKey = breakpoints[index + 1] || 'xl'
      let mq = ''
      if (index === breakpoints.length - 1) {
        mq = `(min-width:${BRAND.media[key]}px)`
      } else {
        
        mq = `(min-width:${BRAND.media[key]}px) and (max-width:${BRAND.media[correctedKey] - 0.5}px)`
      }
      return [correctedKey, mq]
    }
  )

  const [currentBreakpoint, setCurrentBreakpoint] = useState(() => {
    const bp = mqs.find(([, mq]) => window.matchMedia(mq).matches)
    return bp ? bp[0] : 'xs'
  })

  useEffect(() => {
    function handleCurrentBreakpointChange(
      key,
      e
    ) {
      if (e.matches) {
        setCurrentBreakpoint(key)
      }
    }

    const handlers = mqs.map(
      ([key, mq]) => {
        const handler = (e) =>
          handleCurrentBreakpointChange(key, e)
        return [mq, handler]
      }
    )

    handlers.forEach(([mq, handler]) => {
      window.matchMedia(mq).addEventListener('change', handler)
    })

    return () => {
      handlers.forEach(([mq, handler]) => {
        window.matchMedia(mq).removeEventListener('change', handler)
      })
    }
  }, [mqs])

  return currentBreakpoint
}

const useCurrentBreakpoint = singletonHook('xs', _useCurrentBreakpoints)

export { useCurrentBreakpoint }
