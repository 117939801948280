import { useEffect, useState } from "react";
import { singletonHook } from 'react-singleton-hook'

const _useDebouncedWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    function handleResize() {
      setTimeout(() => setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      }), 500)
    }
    
    window.addEventListener("resize", handleResize);
    
    handleResize();
    
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
}

export const useDebouncedWindowSize = singletonHook({ width: undefined, height: undefined }, _useDebouncedWindowSize)