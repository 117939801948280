import Footer from './Footer'
import MegaMenu from './MegaMenu'
import React from 'react'
import { style as s } from './style'
import { BRAND } from '../../constants'

const { media } = BRAND

const Layout = ({ children, megaMenu, footer }) => {
  return (
  <div>
    <MegaMenu {...{ megaMenu }} />
    <main 
    id='main-page'
    className='content'>
      {children}
    </main>
    <Footer {...{ footer }} />
    <style jsx>{`
    .content {
      overflow-x: hidden;
    }
    `}</style>
  </div>
)
  }

export default Layout
