import { STRAPI_IMGX, isActiveLocale } from '../../../constants'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import { normaliseLink } from '../../../controllers/helpers/normaliseLink'
import Image from 'next/image'
import { externalImageLoader } from '../../../utilities/externalImageLoader'
import { listicleMarkdownComponents } from './Listicle'

export const Post = ({ v, i, callToActionURL, locale, blog_article }) => {
  const ASPECT_RATIO = 1.458
  const HEIGHT = 480
  const IMG = { width: HEIGHT * ASPECT_RATIO, height: HEIGHT }
  const LISTICLE_SETTINGS = `?auto=format&sharp=20&con=15&fit=crop&w=${IMG.width * 2}&h=${IMG.height * 2}`

  const renderImage = (venue, num) => {
    return `${STRAPI_IMGX}/${venue.cover[num]?.hash + venue.cover[num]?.ext}${LISTICLE_SETTINGS}`
  }

  const isLandingPageLocaleActive = isActiveLocale(locale)

  const introduction = v.introduction.split(/\n(?!(\s*-|\s*\d+\.))/g).filter((e) => e)

  const getFormattedPostTitle = (index, postName) => {
    return !!blog_article ? postName : `${Number(index) + 1}. ${postName}`
  }

  return (
    <div className='container'>
      <section className='post-images'>
        <div className='img-hero'>
          <Image
            src={renderImage(v, 0)}
            alt={v.cover[0]?.alternativeText}
            loader={externalImageLoader}
            unoptimized
            layout='fill'
            objectFit='cover'
            sizes='(max-width: 1200px) 330px,
              700px'
          />
        </div>
        {v.cover.length === 3 && (
          <div className='secondary-images'>
            <div className='img-top-right'>
              <Image
                src={renderImage(v, 1)}
                alt={v.cover[1]?.alternativeText}
                loader={externalImageLoader}
                unoptimized
                layout='fill'
                objectFit='cover'
                sizes='(max-width: 1200px) 330px,
              700px'
              />
            </div>
            <div className='img-top-left'>
              <Image
                src={renderImage(v, 2)}
                alt={v.cover[2]?.alternativeText}
                loader={externalImageLoader}
                unoptimized
                aria-hidden={true}
                layout='fill'
                objectFit='cover'
                sizes='(max-width: 1200px) 330px,
              700px'
              />
            </div>
          </div>
        )}
      </section>
      <section className={blog_article ? 'post-content blog-article' : 'post-content' }>
        <h3 className='post-title'>{getFormattedPostTitle(i, v.name)}</h3>
        <p className='post-location'>{v.location}</p>
        <div className='post-text'>
          {introduction.map((string, i) => (
            <span key={`post-section-${i}`}>
              <ReactMarkdown components={listicleMarkdownComponents} rehypePlugins={[rehypeRaw]} key={i}>
                {string}
              </ReactMarkdown>
              <br />
            </span>
          ))}
        </div>
        {(!!v.link || isLandingPageLocaleActive)
           && (
            <>
              <br />
              <div className='buttons'>
                {v?.link && (
                  <a
                    className='alternate-button listicle'
                    href={normaliseLink(v.link)}
                    target={!isLandingPageLocaleActive ? '_blank' : undefined}>
                    Tell me more
                  </a>
                )}
                {isLandingPageLocaleActive && !blog_article && (
                  <a className='button listicle' href={callToActionURL}>
                    Match with venues
                  </a>
                )}
              </div>
            </>
          )}
      </section>
      <style jsx>{`
        .container {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          width: 100%;
          max-width: 1200px;
          margin: 20px auto;
        }

        .post-images {
          height: clamp(350px, 54vw, 450px);
          width: 100%;
          display: flex;
          flex-flow: row;
          justify-content: center;
          gap: 8px;
        }

        .img-hero {
          height: 100%;
          max-width: 800px;
          flex: 1;
        }

        .secondary-images {
          width: 33%;
          height: 100%;
          display: flex;
          flex-direction: column;
          gap: 8px;
        }

        .img-top-left,
        .img-top-right {
          flex: 1;
        }

        .img-hero,
        .img-top-left,
        .img-top-right {
          position: relative;
          border-radius: 8px;
          overflow: hidden;
        }

        .post-content {
          max-width: 800px;
          margin: 40px 0 60px;
        }

        .post-content.blog-article {
          margin: 40px 0 10px;
        }

        .post-title {
          margin: 6px;
          font-size: 26px;
          font-weight: 600px;
        }

        .post-location {
          margin: 12px;
        }

        .post-text {
          text-align: left;
          line-height: 1.7;
        }

        .buttons {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
          gap: 16px;
        }

        @media screen and (max-width: 600px) {
          .post-images {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 12px;
          }

          .secondary-images {
            flex-direction: row;
            width: 100%;
            height: 40%;
          }

          .post-text {
            text-align: left;
            line-height: 1.1;
          }
        }
      `}</style>
    </div>
  )
}

export default Post
