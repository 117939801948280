import { HomePage } from './HomePage'
import { LandingPage } from './LandingPage'
import { SimpleContentPage } from './SimpleContentPage'
import { NotFound } from './NotFound'
import { AuthorPage } from './AuthorPage'

export const templates = {
  HomePage,
  LandingPage,
  SimpleContentPage,
  NotFound,
  AuthorPage
}