import { BRAND } from '../../constants'
const { media } = BRAND

const Station = ({ station }) => <>{station && station.replace(/Underground/g, '')}</>

export const LocalisedDistance = ({ km, unit }) => {
  if (!km) return ""

  if (unit === 'mi') {
    const roundMiles = (km * 0.621371).toFixed(1)
    return <>{' '}({`${roundMiles} mi`})</>
  }

  return <>{' '}({`${km.toFixed(1)} km`})</>
}

export const TransitInfo = ({ transit, locale }) => {
  let { station, distance_km } = transit
  return (
    <section className='row'>
      {station && (
        <img src={'/ilp-public/assets/images/location.png'} className='icon' alt={`is close to ${station}`} />
      )}

      <h5 className='subTitle'>
        <Station station={station} /> 
        <LocalisedDistance km={distance_km} unit={locale === 'US' ? 'mi' : 'km'} />
      </h5>
      <style jsx>{`
        .subTitle {
          line-height: 24px;
          color: ${BRAND.textColour};
          font-size: 16px;
          text-align: left;
          flex: 1;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .icon {
          width: 16px;
          height: 16px;
          font-size: 8px;
          margin-right: 5px;
        }
        .row {
          display: flex;
          flex-direction: row;
          align-items: center;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-right: 8px;
        }
      `}</style>
    </section>
  )
}
