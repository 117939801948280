import Head from 'next/head'
import { BRAND } from '../constants'
import { Layout } from '../components/Layout'
import { TargetContent, HowTo, PrimaryCTA } from '../components/UI'
import { Announcements, Hero } from '../components/HomePage'
import { Gallery, Meta } from '../components/Landing'
import { ListicleContentBlock } from '../components/Landing/Listicle/ContentBlock/ListicleContentBlock'
import { TrustedByBanner } from '../components/Landing/TrustedByBanner'
import getCallToActionUrl from '../utilities/getCallToActionURL'

const canonicalURLSuffix = {
  GB: '/',
  AU: '/au',
  US: '/us'
}

const formatCanonicalHref = (href, localeString) => {
  const suffix = canonicalURLSuffix[localeString] ? canonicalURLSuffix[localeString] : '/'
  return `https://${href}${suffix}`
}

export const HomePage = ({
  host,
  megaMenu,
  announcementBar,
  homePage,
  trustedByBanner,
  targetLocations,
  targetVenues,
  targetEvents,
  targetBlogs,
  footer,
  spaceCard,
  howItWorks,
  spaces,
  primaryCta,
  locale
}) => {
  const { primaryContent, secondaryContent } = homePage
  const callToActionURL = getCallToActionUrl({ locale })
  
  return (
    <div className='hiddenOverflow'>
      <Layout {...{ navType: 'STANDARD', megaMenu, footer }}>
        <Head>
          <link rel='canonical' href={formatCanonicalHref(host, locale)} />
          <link rel='alternate' href={`https://${host}/`} hrefLang='en-gb' />
          <link rel='alternate' href={`https://${host}/au`} hrefLang='en-au' />
          <link rel='alternate' href={`https://${host}/us`} hrefLang='en-us' />
          <link rel='alternate' href={`https://${host}/`} hrefLang='x-default' />
        </Head>
        { announcementBar && <Announcements {...{ announcementBar }} />}
        <Meta
          metaTitle='HeadBox │ Stop searching and let the perfect venue find you!'
          metaDescription={`Get fast, personalised responses from available venues that match what you’re looking for. No long searches, no hidden costs, no stress.`}
        />
        <Hero {...{ homePage, callToActionURL }} />
        { trustedByBanner && <TrustedByBanner trustedByBanner={trustedByBanner} />}
        <div className='container'>
            <ListicleContentBlock type='primary' contentBlock={primaryContent} />
            {targetEvents && <TargetContent target={targetEvents} />}
            <HowTo {...{ howItWorks, callToActionURL }} />
            {targetVenues && <TargetContent target={targetVenues} />}
            <ListicleContentBlock type='secondary' contentBlock={secondaryContent} />
            {targetLocations && <TargetContent target={targetLocations} />}
            {targetBlogs && <TargetContent target={targetBlogs} />}
          <br />
          {spaces.length > 0 && (
            <>
              <h2 className='spaces' style={{ marginBottom: 0, marginTop: 20 }}>
                Spaces to inspire
              </h2>
              <Gallery {...{ spaceCard, spaces, locale }} />
            </>
          )}
          <PrimaryCTA {...{ primaryCta, callToActionURL }} />
        </div>
      </Layout>
      <style jsx>{`
        .div {
          width: 100px;
          height: 100px;
          color: hotpink;
        }
        .carousel-next {
          filter: drop-shadow(0px 1px 2px #4a4a4a);
          width: 40px;
          height: 40px;
          padding: 20px;
          background-color: transparent;
          border: none;
          outline: none;
        }
        .container {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
        }
        .spaces {
          font-size: 32px;
        }
        @media screen and (max-width: ${BRAND.media.medium}px) {
          .anchor {
            font-size: 16px;
          }
          .icon {
            width: 9px;
            height: 9px;
            padding: 0px 6px 0px 0px;
          }
          .breadcrumb {
            font-size: 16px;
          }
        }
        @media screen and (max-width: ${BRAND.media.tablet}px) {
          .spaces {
            font-size: 28px;
            line-height:1.3;
          }
        }
          @media screen and (max-width: ${BRAND.media.small}px) {
            .spaces {
              font-size: 24px;
            }
          }
        }
      `}</style>
    </div>
  )
}
