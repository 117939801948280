import { BRAND } from '../../constants'
import { externalImageLoader } from '../../utilities/externalImageLoader'
import { ButtonLink } from '../UI'
import { STRAPI_IMGX } from '../../constants'
import Image from 'next/image'

const layout = {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  gap: 24,
  padding: '0 16px',
  textWrap: 'balance'
}

const renderImage = (heroBackgroundImage) => {
  return `${STRAPI_IMGX}/${heroBackgroundImage.hash + heroBackgroundImage.ext}?auto=format&h=500&w=1500&fit=crop`
}

const Hero = ({ homePage, callToActionURL }) => {
  const { heroBackgroundImage, heroCTA, heroBlurb, heroHeadline } = homePage

  return (
    <header style={{ position: 'relative', overflow: 'hidden', width: '100%' }}>
      <section className='standfirst-section'>
        <div style={layout}>
          <h1 className='standfirst'>
            {heroHeadline === 'Events made easy.' ? (
              <>
                Events made <br /> easy.
              </>
            ) : (
              heroHeadline
            )}
          </h1>
          <p className='subheader'>{heroBlurb}</p>
          <section className='cta-button'>
            <ButtonLink href={callToActionURL} home>
              {heroCTA}
            </ButtonLink>
          </section>
        </div>
      </section>
      <div className='hero-wrapper'>
        <Image
          alt='Events made easy at HeadBox'
          layout='fill'
          src={renderImage(heroBackgroundImage)}
          loader={externalImageLoader}
          aria-hidden='true'
          objectFit='cover'
          priority={true}
          className='img'
        />
      </div>
      <style jsx>{`
        .buttons {
          display: flex;
          aling-items: center;
          justify-content: center;
          flex-direction: row;
          margin: 0px 20px 20px 20px;
        }
        .hero-wrapper {
          width: 100%;
          object-fit: cover;
          height: 568px;
          position: relative;
          object-position: 50% 50%;
        }
        .standfirst-section {
          position: absolute;
          height: 569px;
          width: 100%;
          display: flex;
          font-weight: bold;
          justify-content: center;
          flex-direction: column;
          z-index: 20;
        }
        .standfirst {
          color: white;
          text-align: center;
          font-size: 64px;
          line-height: 68px;
          max-width: 710px;
        }
        .subheader {
          color: white;
          text-align: center;
          font-weight: 400;
          font-size: 18px;
          max-width: 540px;
        }
        .cta-title {
          color: white;
          text-align: center;
          margin-top: 24px;
        }

        @media screen and (max-width: ${BRAND.media.small}px) {
          .hero-wrapper {
            height: 500px;
          }
          .standfirst-section {
            height: 500px;
          }
          .subheader {
          }
          .cta-title {
            color: white;
            text-align: center;
            font-size: 22px;
            margin-top: 0px;
          }
          .standfirst {
            font-size: 32px;
            line-height: 38px;
            padding: 10px;
            max-width: 400px;
          }
          .looking-for {
            font-size: 20px;
            margin-top: 12px;
            color: white;
          }
        }
      `}</style>
    </header>
  )
}

export default Hero
