import Image from 'next/image'
import { AuthorNames } from './AuthorNames'
import { getFormattedISODate } from '../../controllers/helpers/index'
import { BRAND, STRAPI_IMGX } from '../../constants'
import { externalImageLoader } from '../../utilities/externalImageLoader'

const renderImage = (listicle) => {
  return `${STRAPI_IMGX}/${listicle.hash + listicle.ext}`
}

export const AuthorListicleCard = ({ listicle, baseUrl, authorLocale, authorId }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div className='author-listicle-card'>
        <div className='author-post-image'>
          {listicle.url ? (
            <Image
            loading='lazy'
              src={renderImage(listicle)}
              loader={externalImageLoader}
              alt={`${listicle.title}`}
              layout='fill'
              objectFit='cover'
            />
          ) : (
            <div className='placeholder-image'>
              <span className='placeholder-text'>{listicle.title}</span>
            </div>
          )}
        </div>
        <div className='author-listicle-content'>
          <h2 className='author-listicle-title'>{listicle.title}</h2>
          <AuthorNames authorId={authorId} authors={listicle.authors} baseUrl={baseUrl} />
          <div className='author-listicle-date'>{getFormattedISODate(listicle.updated_at, authorLocale)}</div>
        </div>
      </div>

      <style jsx>{`
        .author-listicle-card {
          display: grid;
          grid-template-columns: 60% 1fr;
          border-radius: 8px;
          overflow: hidden;
          background-color: white;
          width: 70%;
        }

        .author-listicle-content {
          padding: 31px 24px;
        }

        .author-post-image {
          width: 100%;
          object-fit: cover;
          overflow: hidden;
          height: 370px;
          position: relative;
          text-decoration: none;
        }

        .author-listicle-title {
          font-size: 24px;
          font-weight: bold;
          color: black;
          font-family: Montserrat;
          line-height: 38px;
          padding-bottom: 10px;
        }

        .author-listicle-date {
          font-size: 16px;
          color: #595959;
          font-family: Montserrat;
        }

        .placeholder-image {
          background-color: #af231c;
          background: linear-gradient(108deg, #af231c 25.28%, #5c110e 102.38%);
          width: 100%;
          height: 370px;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          padding: 20px;
          text-decoration: none;
        }

        .placeholder-text {
          color: var(--grey-1, #fff);
          font-family: Montserrat;
          font-size: 64px;
          font-style: normal;
          font-weight: 700;
          line-height: 60px;
        }

        @media screen and (max-width: ${BRAND.media.small}px) {
          .author-listicle-card {
            display: grid;
            grid-template-columns: 1fr;
            border-radius: 8px;
            overflow: hidden;
            background-color: white;
            width: 90%;
          }

          .author-listicle-content {
            padding: 24px;
          }

          .placeholder-text {
            font-size: 48px;
          }
        }
      `}</style>
    </div>
  )
}
