import { BRAND } from '../../constants'

const { card, primaryColour } = BRAND
const { minWidth } = card
const bannerWidth = 1230

export const LANDING_PAGE_STYLE = {
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center'
  }
}

export const HEADING_STYLE = {
  breadCrumb: {
    fontWeight: 400,
    textTransform: 'capitalize',
    fontSize: 20,
    letterSpacing: 2,
    padding: '0px 5px'
  }
}

export const SECONDARY_CTA_STYLE = {
  title: {
    fontWeight: 'bold',
    fontSize: '42px',
    lineHeight: '50px',
    color: 'white',
    maxWidth: 400
  },
  paragraph: {
    fontSize: 18,
    lineHeight: 1.9,
    color: 'white',
    fontWeight: 400,
    maxWidth: 300,
    margin: '10px 0px 0px 0px'
  },
  button: {
    backgroundColor: 'white',
    color: primaryColour,
    borderRadius: 6,
    border: `2px solid ${primaryColour}`,
    height: 40,
    padding: 10,
    width: 320,
    fontWeight: 600,
    fontSize: 16,
    margin: '20px 0px',
    cursor: 'pointer'
  },
  card: {
    maxWidth: 400,
    height: 330,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexDirection: 'column',
    padding: 10
  },
  banner: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'center',
    width: bannerWidth,
    minWidth,
    maxWidth: bannerWidth,
    minHeight: 330,
    backgroundColor: primaryColour,
    borderRadius: 20,
    margin: '40px 0px'
  }
}
