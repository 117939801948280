import { AuthorListicleCard } from './AuthorListicleCard'
import { Pagination } from '../Landing/Pagination'
import { BRAND } from '../../constants'

export const AuthorPosts = ({
  listicles,
  firstName,
  authorLocale,
  pagination,
  totalListicleCount,
  baseUrl,
  authorId
}) => {
  return (
    <>
      <div className='author-posts-container'>
        <text className='author-posts-title'>
          All {firstName}'s posts ({totalListicleCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')})
        </text>
        <Pagination {...{ pagination }}>
          <div className='author-posts-list'>
            {listicles.map((listicle, i) => (
              <a key={listicle.id} href={listicle.listicleUrl} className='author-post-link'>
                <AuthorListicleCard authorId={authorId} baseUrl={baseUrl} listicle={listicle} authorLocale={authorLocale} />
              </a>
            ))}
          </div>
        </Pagination>
      </div>

      <style jsx>{`
        .author-posts-container {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          margin: 0 auto;
          background-color: #f5f5f5;
          color: var(--grey-11, #1f1f1f);
          font-family: Montserrat;
          font-size: 32px;
          font-weight: 700;
          width: 100%;
          padding-top: 36px;
        }

        .author-posts-title {
          height: 52px;
          margin-bottom: 16px;
        }

        .author-posts-list {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 64px;
          width: 100%;
        }

        .author-post-link {
          text-decoration: none;
          width: 100%;
          height: 100%;
        }

        @media screen and (max-width: ${BRAND.media.small}px) { 
          .author-posts-list {
            gap: 32px; /* Reduce gap to 32px on mobile */
          }
      `}</style>
    </>
  )
}
