import { BANNER_STYLE as s, SECONDARY_CTA_STYLE as sec } from './style'
import { BRAND } from '../../constants'
const { media } = BRAND

export const SecondaryCTA = ({ secondaryCta, collectionId }) => {
  const { title, blurb, cta } = secondaryCta
  const query = collectionId ? `?collection=${collectionId}` : ''

  return (
    <section style={s.container}>
      <div style={sec.banner} className='banner'>
        <section style={sec.card}>
          <h3 style={sec.title}>{title}</h3>
          <p style={sec.paragraph}>{blurb}</p>
          <button
            id='#secondary-cta'
            tabIndex={1}
            style={sec.button}
            href={`${process.env.NEXT_PUBLIC_BRIEF_BUILDER}${query}`}
            >
            {cta}
          </button>
        </section>
        <div className='img'>
          <img
            alt={title}
            aria-hidden={true}
            height='0.55'
            width='1'
            src={`/ilp-public/assets/images/messagesIcon.png`}
          />
        </div>
        <style jsx>{`
          .banner {
            display: flex;
            align-items: center;
          }
          .img {
            object-fit: cover;
            height: 330px;
            width: 500px;
            padding-top: 20px;
          }
          @media screen and (max-width: ${media.average}px) {
            .img {
              height: 310px;
            }
          }
          @media screen and (max-width: ${media.medium}px) {
            .img {
              height: 280px;
              padding-top: 10px;
            }
          }
          @media screen and (max-width: ${media.small}px) {
            .img {
              padding: 0px;
              height: 230px;
            }
          }
        `}</style>
      </div>
    </section>
  )
}
